// BMW colors
@import './variables.scss';
@import './skeleton.scss';
@import './controls.scss';
@import './styles.scss';
@import './icons.scss';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--ds-color-typography-base);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  --ds-font-size-input-label: $font-size;
}

.stretch-height {
  height: 100%;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
  align-items: flex-start;
}

.flex-stretch-vertically {
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.one-column {
  & > div {
    padding: 0;
    padding-right: 24px;
  }
}

div.five-columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & > div {
    padding: 0;
    padding-right: 24px;

    & > button {
      margin-top: 18px;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    & > div {
      width: 20%;
    }
  }
}

.three-columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & > div {
    padding: 0;
    padding-right: 24px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    & > div {
      width: 33.333333%;
    }
  }
}

div.two-columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & > div {
    padding: 0;
    padding-right: 24px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    & > div {
      width: 33.333333%;
    }
  }
}

.spaceBetweenProperties {
  padding-bottom: 0px;
  margin-bottom: 24px;
}

.spaceBetweenTitles {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 24px;
}

.spaceBetweenTitlesNew {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}

.lineSep {
  display: block;
  overflow: hidden;
  margin: 24px 0;
  border: 1px solid $color-control-separator;
  border-top: 0;

  &.thick {
    border-width: 2px;
    margin-bottom: 16px;
  }
  &.thin {
    &.edit {
      color: #e1e4ea;
    }
  }
  &.light {
    opacity: 0.5;
  }
}

.marginSeparator {
  margin-bottom: 24px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e0e0e0;
}

body .tableNoContentMessage2 {
  color: #999990;
  font-size: 24px;
}

.ds-box__content-wrapper {
  overflow-y: auto !important;
}

.graphLineSep {
  color: #bdc6d6;
  display: block;
  overflow: hidden;

  &.thin {
    border-style: solid;
    border-width: 0.5px;
  }

  &.thick {
    border-style: double;
    border-width: 1px;
  }
  &.spacing {
    padding-top: 24px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin: 0;
  }
  &.overview {
    margin-top: 0;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }
  &:not([href]):not([class]) {
    //avoids disabled link styling when only method call
    color: var(--ds-color-typography-action) !important;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-lighter {
  font-weight: lighter;
}

.ml-n2 {
  margin-left: -8px;
}

.ml-n4 {
  margin-left: -16px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;

  h2 {
    flex: 1 1 auto;
    margin: 8px 0;
  }
}

.tooltip-header {
  display: block;
  margin-bottom: 4px;
}

.searchDiv {
  display: inline-flex;
  background-color: $color-control-alternative;
  border-bottom: 1px solid $color-control-border;
  height: 32px;
  width: 280px;

  &:hover {
    border-color: $color-control-hover-on;
  }

  .p-inputtext.searchInput {
    background-color: transparent;
    border-bottom: none;
  }

  // TODO: to be removed when whole search field is replaced with input & icon
  .ds-button.ds-button--ghost:hover {
    background-color: transparent;
    cursor: default;
  }
}

.container-last {
  display: flex;
  position: relative;
  align-items: center;
  height: 32px;
}

.container-last.parent {
  overflow: unset;
}

.content-area {
  flex: 1 1 auto;
  @media screen and (min-width: 576px) {
    padding: 24px;
    overflow: auto scroll;
  }
}

.p-toast {
  z-index: 6000 !important;
}

.filter-gray {
  filter: grayscale(1);
  opacity: 0.5;
}

.center-horizontal {
  justify-content: center;
  display: flex;
}

.toggle-box-header {
  display: flex;
  align-items: center;

  ds-switch {
    margin-left: 8px;
  }
}

.fake-toggle {
  cursor: pointer;
  ds-switch {
    pointer-events: none;
  }
}

.ag-theme-density .ag-row {
  font-size: 14px;
}

.ag-row.ag-row-selected {
  //workaround for AG Grid Density bug
  background-color: var(--row-selected-background-color) !important;
}

.small-menu-items {
  width: 100px;
}

// Will be removed when removing primeNG tree:
.p-tree,
.p-treenode,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight,
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover,
.p-card {
  background-color: var(--ds-color-surface-base);
  color: var(--ds-color-typography-base);
}
