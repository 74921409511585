@import './variables/fonts.scss';
@import './variables/colors.scss';

$icon-size-extra-large: 100px;

.icon-success-extra-large {
  font-size: $icon-size-extra-large;
  color: $color-success;
}

.icon-toolbar-button-eye {
  font-size: $icon-font-size;
  font-weight: normal !important;
}
