@charset "UTF-8";
/* Basic Colors */
/* Highlight and Accent Colors */
/* More Colors */
@font-face {
  font-family: BMWGroup_Condensed;
  src: url("fonts/BMWGroupCondensed-Regular.ttf") format("truetype"), url("fonts/BMWGroupCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: BMWGroup_Condensed;
  src: url("fonts/BMWGroupCondensed-Bold.ttf") format("truetype"), url("fonts/BMWGroupCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "iwp";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/iwp.eot");
  src: url("fonts/iwp.eot?#iefix") format("embedded-opentype"), url("fonts/iwp.woff2") format("woff2"), url("fonts/iwp.woff") format("woff"), url("fonts/iwp.ttf") format("truetype"), url("fonts/iwp.svg#iwp") format("svg");
}
[class^=iwp-icon-],
[class*=" iwp-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iwp" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iwp-icon-account_substitute_admin:before {
  content: "\e9e5";
}

.iwp-icon-active_workspace:before {
  content: "\ea82";
}

.iwp-icon-as_favorite:before {
  content: "\eaa1";
}

.iwp-icon-as_new:before {
  content: "\ea92";
}

.iwp-icon-as_search:before {
  content: "\eaa2";
}

.iwp-icon-as_structure:before {
  content: "\eaa9";
}

.iwp-icon-as_swc_new:before {
  content: "\ea9f";
}

.iwp-icon-as_swc_search:before {
  content: "\eaa0";
}

.iwp-icon-auvere:before {
  content: "\eb8d";
}

.iwp-icon-b2online:before {
  content: "\e9e6";
}

.iwp-icon-bdqv:before {
  content: "\e901";
}

.iwp-icon-bdqv_next:before {
  content: "\eb7a";
}

.iwp-icon-bdqv_next_alternative:before {
  content: "\eb8e";
}

.iwp-icon-beast:before {
  content: "\eba8";
}

.iwp-icon-bmw_intranet_search:before {
  content: "\e902";
}

.iwp-icon-boa:before {
  content: "\eba5";
}

.iwp-icon-booletool:before {
  content: "\ebe2";
}

.iwp-icon-cassini:before {
  content: "\eac3";
}

.iwp-icon-ci_anker:before {
  content: "\eb16";
}

.iwp-icon-ci_apps:before {
  content: "\eb15";
}

.iwp-icon-ci_at_symbol:before {
  content: "\eb3d";
}

.iwp-icon-ci_calendar_clock:before {
  content: "\eb1b";
}

.iwp-icon-ci_calendar_doc:before {
  content: "\eb50";
}

.iwp-icon-ci_customer_benefits:before {
  content: "\eb4a";
}

.iwp-icon-ci_dealer_benefits:before {
  content: "\eb4b";
}

.iwp-icon-ci_facebook:before {
  content: "\eb23";
}

.iwp-icon-ci_gallery:before {
  content: "\eb40";
}

.iwp-icon-ci_goal:before {
  content: "\eb17";
}

.iwp-icon-ci_google:before {
  content: "\eb34";
}

.iwp-icon-ci_graduation_cap:before {
  content: "\eb4c";
}

.iwp-icon-ci_highquality:before {
  content: "\eb42";
}

.iwp-icon-ci_instagram:before {
  content: "\eb3f";
}

.iwp-icon-ci_joypad:before {
  content: "\eb32";
}

.iwp-icon-ci_lightbulb:before {
  content: "\eb4d";
}

.iwp-icon-ci_like:before {
  content: "\eb18";
}

.iwp-icon-ci_linechart:before {
  content: "\eb4e";
}

.iwp-icon-ci_linkedin:before {
  content: "\eb3e";
}

.iwp-icon-ci_list:before {
  content: "\eb4f";
}

.iwp-icon-ci_location:before {
  content: "\eb3c";
}

.iwp-icon-ci_lowquality:before {
  content: "\eb41";
}

.iwp-icon-ci_maximize:before {
  content: "\eb19";
}

.iwp-icon-ci_minimize:before {
  content: "\eb1a";
}

.iwp-icon-ci_move:before {
  content: "\eb3b";
}

.iwp-icon-ci_move_horizontally:before {
  content: "\eb3a";
}

.iwp-icon-ci_move_vertically:before {
  content: "\eb39";
}

.iwp-icon-ci_movie:before {
  content: "\eb43";
  color: #474747;
}

.iwp-icon-ci_movie_doc:before {
  content: "\eb45";
}

.iwp-icon-ci_mute:before {
  content: "\eb38";
}

.iwp-icon-ci_paperclip:before {
  content: "\eb47";
}

.iwp-icon-ci_pause:before {
  content: "\eb36";
}

.iwp-icon-ci_pause_active:before {
  content: "\eb37";
}

.iwp-icon-ci_phone:before {
  content: "\eb33";
}

.iwp-icon-ci_pin:before {
  content: "\eb35";
}

.iwp-icon-ci_pinterest:before {
  content: "\eb26";
}

.iwp-icon-ci_play:before {
  content: "\eb31";
}

.iwp-icon-ci_play_off:before {
  content: "\eb2a";
}

.iwp-icon-ci_presentation_doc:before {
  content: "\eb44";
}

.iwp-icon-ci_quotationmark:before {
  content: "\eb30";
}

.iwp-icon-ci_rss:before {
  content: "\eb2f";
}

.iwp-icon-ci_scrolling:before {
  content: "\eb1c";
}

.iwp-icon-ci_shuffle:before {
  content: "\eb1d";
}

.iwp-icon-ci_sound_doc:before {
  content: "\eb48";
}

.iwp-icon-ci_sound_stream:before {
  content: "\eb49";
}

.iwp-icon-ci_speaker:before {
  content: "\eb1e";
}

.iwp-icon-ci_subtitle_off:before {
  content: "\eb2c";
}

.iwp-icon-ci_subtitle_on:before {
  content: "\eb2d";
}

.iwp-icon-ci_swipe:before {
  content: "\eb20";
}

.iwp-icon-ci_table_doc:before {
  content: "\eb46";
}

.iwp-icon-ci_touch:before {
  content: "\eb1f";
}

.iwp-icon-ci_trophy:before {
  content: "\eb24";
}

.iwp-icon-ci_turn_around:before {
  content: "\eb28";
}

.iwp-icon-ci_twitter:before {
  content: "\eb2b";
}

.iwp-icon-ci_video_stream:before {
  content: "\eb29";
}

.iwp-icon-ci_visualbrowsing:before {
  content: "\eb27";
}

.iwp-icon-ci_volume:before {
  content: "\eb2e";
}

.iwp-icon-ci_whatsapp:before {
  content: "\eb25";
}

.iwp-icon-ci_xing:before {
  content: "\eb21";
}

.iwp-icon-ci_youtube:before {
  content: "\eb22";
}

.iwp-icon-csc:before {
  content: "\ea41";
}

.iwp-icon-csc_asn_quality:before {
  content: "\eaaa";
}

.iwp-icon-csc_belomdeliveries:before {
  content: "\ea59";
}

.iwp-icon-csc_bulb:before {
  content: "\eaa3";
}

.iwp-icon-csc_capacitystoragetype:before {
  content: "\ea5a";
}

.iwp-icon-csc_capacityunloadingpoint:before {
  content: "\ea5b";
}

.iwp-icon-csc_contactdata:before {
  content: "\ea5c";
}

.iwp-icon-csc_eventchain:before {
  content: "\ea5d";
}

.iwp-icon-csc_inboundtrucks:before {
  content: "\ea5e";
}

.iwp-icon-csc_jitjis:before {
  content: "\ead4";
}

.iwp-icon-csc_opendeliveries:before {
  content: "\ea5f";
}

.iwp-icon-csc_overview:before {
  content: "\ea60";
}

.iwp-icon-csc_runout_calculator:before {
  content: "\eb0d";
}

.iwp-icon-csc_shortagecontrol:before {
  content: "\ea61";
}

.iwp-icon-csc_supplycontrol:before {
  content: "\ea62";
}

.iwp-icon-csc_supplysituation:before {
  content: "\ea63";
}

.iwp-icon-csc_threshold:before {
  content: "\eb0b";
}

.iwp-icon-csc_tracktrace:before {
  content: "\ea64";
}

.iwp-icon-csc_workbasket:before {
  content: "\ea65";
}

.iwp-icon-csc_workbasket_dataquality:before {
  content: "\eaab";
}

.iwp-icon-da_dataexchange:before {
  content: "\ea86";
}

.iwp-icon-da_importmessages:before {
  content: "\ea87";
}

.iwp-icon-da_status:before {
  content: "\ea88";
}

.iwp-icon-da_targets:before {
  content: "\ea89";
}

.iwp-icon-dali:before {
  content: "\ea7e";
}

.iwp-icon-dbkr_task:before {
  content: "\eb04";
}

.iwp-icon-default_app:before {
  content: "\ea03";
}

.iwp-icon-dic:before {
  content: "\ea7f";
}

.iwp-icon-diva:before {
  content: "\eba6";
}

.iwp-icon-dpv:before {
  content: "\ea0a";
}

.iwp-icon-dsmon:before {
  content: "\ea26";
}

.iwp-icon-dubai:before {
  content: "\eb56";
}

.iwp-icon-dza:before {
  content: "\eaac";
}

.iwp-icon-earl:before {
  content: "\eb59";
}

.iwp-icon-ebaureihen:before {
  content: "\ea30";
}

.iwp-icon-efa:before {
  content: "\e9e3";
}

.iwp-icon-efa_change_position:before {
  content: "\eb88";
}

.iwp-icon-efa_clip_new_d:before {
  content: "\eb00";
}

.iwp-icon-efa_clip_new_e:before {
  content: "\eb01";
}

.iwp-icon-efa_clip_old_d:before {
  content: "\eb02";
}

.iwp-icon-efa_clip_old_e:before {
  content: "\eb03";
}

.iwp-icon-efa_mass_update:before {
  content: "\eb89";
}

.iwp-icon-efa_mass_update_edit:before {
  content: "\ebe4";
}

.iwp-icon-efa_mass_update_finish:before {
  content: "\ebe5";
}

.iwp-icon-ekgq:before {
  content: "\e938";
}

.iwp-icon-elam:before {
  content: "\ea93";
}

.iwp-icon-emt:before {
  content: "\e903";
}

.iwp-icon-eve:before {
  content: "\e904";
}

.iwp-icon-eve_aerodynamics:before {
  content: "\e905";
}

.iwp-icon-eve_assign:before {
  content: "\e906";
}

.iwp-icon-eve_catalog:before {
  content: "\e907";
}

.iwp-icon-eve_configuration:before {
  content: "\e908";
}

.iwp-icon-eve_data_maintenance:before {
  content: "\e909";
}

.iwp-icon-eve_examination:before {
  content: "\e90a";
}

.iwp-icon-eve_group:before {
  content: "\e90b";
}

.iwp-icon-eve_homologation:before {
  content: "\e90c";
}

.iwp-icon-eve_manual:before {
  content: "\e90d";
}

.iwp-icon-eve_market:before {
  content: "\e90e";
}

.iwp-icon-eve_new_car:before {
  content: "\e90f";
}

.iwp-icon-eve_pkw_outline:before {
  content: "\e9b4";
}

.iwp-icon-eve_rowi:before {
  content: "\e910";
}

.iwp-icon-eve_salesportal:before {
  content: "\e911";
}

.iwp-icon-everest:before {
  content: "\ea17";
}

.iwp-icon-everest_bottleneck_forecast:before {
  content: "\ebb1";
}

.iwp-icon-everest_bt_per_time_unit:before {
  content: "\ebb2";
}

.iwp-icon-everest_capa:before {
  content: "\ea13";
}

.iwp-icon-everest_capa_history:before {
  content: "\eaa4";
}

.iwp-icon-everest_capa_level:before {
  content: "\ebb3";
}

.iwp-icon-everest_car_project_progress:before {
  content: "\ebb4";
}

.iwp-icon-everest_delivery_reliability:before {
  content: "\ebb5";
}

.iwp-icon-everest_demand_changes:before {
  content: "\ebb6";
}

.iwp-icon-everest_demandcarrier_information:before {
  content: "\ebb7";
}

.iwp-icon-everest_expedited_material_supply:before {
  content: "\ebb8";
}

.iwp-icon-everest_hundert_percent_ordered:before {
  content: "\ebb9";
}

.iwp-icon-everest_input_material_control:before {
  content: "\ebba";
}

.iwp-icon-everest_kpi:before {
  content: "\ebc1";
}

.iwp-icon-everest_leadtime:before {
  content: "\ea14";
}

.iwp-icon-everest_material_information:before {
  content: "\ebc2";
}

.iwp-icon-everest_material_master_changes:before {
  content: "\ebc3";
}

.iwp-icon-everest_new_demand:before {
  content: "\ebc4";
}

.iwp-icon-everest_relevant_material_number:before {
  content: "\ebbb";
}

.iwp-icon-everest_reporting_capa:before {
  content: "\ebd0";
}

.iwp-icon-everest_same_material_number:before {
  content: "\ebd1";
}

.iwp-icon-everest_volume_of_changes:before {
  content: "\ebbc";
}

.iwp-icon-fls:before {
  content: "\e912";
}

.iwp-icon-fls_aes:before {
  content: "\e913";
}

.iwp-icon-fls_bodyshell_outline:before {
  content: "\e914";
}

.iwp-icon-fls_ccb:before {
  content: "\e915";
}

.iwp-icon-fls_derivative_outline:before {
  content: "\e916";
}

.iwp-icon-fls_detailtext:before {
  content: "\e917";
}

.iwp-icon-fls_doc_freig_ok:before {
  content: "\e93a";
}

.iwp-icon-fls_doc_geo_ok:before {
  content: "\e93b";
}

.iwp-icon-fls_doc_ok:before {
  content: "\e918";
}

.iwp-icon-fls_escalation:before {
  content: "\e919";
}

.iwp-icon-fls_express_mode:before {
  content: "\e91a";
}

.iwp-icon-fls_factory:before {
  content: "\e91b";
}

.iwp-icon-fls_gfb:before {
  content: "\e91c";
}

.iwp-icon-fls_i_stages:before {
  content: "\e91d";
}

.iwp-icon-fls_kommunal:before {
  content: "\e922";
}

.iwp-icon-fls_module:before {
  content: "\e926";
}

.iwp-icon-fls_nael_no_prisma:before {
  content: "\e927";
}

.iwp-icon-fls_part:before {
  content: "\e928";
}

.iwp-icon-fls_part2structure:before {
  content: "\e92a";
}

.iwp-icon-fls_part_list:before {
  content: "\e929";
}

.iwp-icon-fls_part_neu:before {
  content: "\e9b3";
}

.iwp-icon-fls_progress:before {
  content: "\e92b";
}

.iwp-icon-fls_signing:before {
  content: "\e92c";
}

.iwp-icon-ftm:before {
  content: "\eb51";
}

.iwp-icon-gams:before {
  content: "\e92d";
}

.iwp-icon-gams_task:before {
  content: "\eb05";
}

.iwp-icon-gen_add:before {
  content: "\e92e";
}

.iwp-icon-gen_add_col:before {
  content: "\e950";
}

.iwp-icon-gen_add_favorite:before {
  content: "\e9d5";
}

.iwp-icon-gen_add_folder:before {
  content: "\e9d6";
}

.iwp-icon-gen_add_link:before {
  content: "\eb5a";
}

.iwp-icon-gen_add_version:before {
  content: "\ea58";
}

.iwp-icon-gen_aggregation:before {
  content: "\eb5b";
}

.iwp-icon-gen_allcolors:before {
  content: "\ea56";
}

.iwp-icon-gen_archive:before {
  content: "\ea1f";
}

.iwp-icon-gen_arrow_down:before {
  content: "\e92f";
}

.iwp-icon-gen_arrow_left:before {
  content: "\e930";
}

.iwp-icon-gen_arrow_right:before {
  content: "\e931";
}

.iwp-icon-gen_arrow_up:before {
  content: "\e932";
}

.iwp-icon-gen_assign_to_me:before {
  content: "\ea3a";
}

.iwp-icon-gen_back:before {
  content: "\e933";
}

.iwp-icon-gen_barchart:before {
  content: "\ea0c";
}

.iwp-icon-gen_bell:before {
  content: "\ea3b";
}

.iwp-icon-gen_by_hand:before {
  content: "\eb06";
}

.iwp-icon-gen_calc:before {
  content: "\e934";
}

.iwp-icon-gen_calendar:before {
  content: "\e93c";
}

.iwp-icon-gen_calendar_today:before {
  content: "\e9d7";
}

.iwp-icon-gen_car:before {
  content: "\ea1c";
}

.iwp-icon-gen_car_config:before {
  content: "\e93d";
}

.iwp-icon-gen_chart:before {
  content: "\e921";
}

.iwp-icon-gen_chat:before {
  content: "\e9e7";
}

.iwp-icon-gen_check_data:before {
  content: "\eb5c";
}

.iwp-icon-gen_checkbox_checked:before {
  content: "\e900";
}

.iwp-icon-gen_checkbox_excluded:before {
  content: "\eba7";
}

.iwp-icon-gen_checkbox_subset:before {
  content: "\eb5d";
}

.iwp-icon-gen_checkbox_unchecked:before {
  content: "\e91e";
}

.iwp-icon-gen_checked_OK:before {
  content: "\e9cd";
}

.iwp-icon-gen_checked_folder:before {
  content: "\ea23";
}

.iwp-icon-gen_close:before {
  content: "\e93e";
}

.iwp-icon-gen_close_l:before {
  content: "\e93f";
}

.iwp-icon-gen_close_s:before {
  content: "\e940";
}

.iwp-icon-gen_collection_collapse:before {
  content: "\e941";
}

.iwp-icon-gen_collection_expand:before {
  content: "\e942";
}

.iwp-icon-gen_comment:before {
  content: "\e943";
}

.iwp-icon-gen_compare:before {
  content: "\eb5e";
}

.iwp-icon-gen_confidental:before {
  content: "\e944";
}

.iwp-icon-gen_confirm:before {
  content: "\eafb";
}

.iwp-icon-gen_continue:before {
  content: "\e945";
}

.iwp-icon-gen_copy:before {
  content: "\e946";
}

.iwp-icon-gen_cut:before {
  content: "\e947";
}

.iwp-icon-gen_database:before {
  content: "\ea00";
}

.iwp-icon-gen_del_col:before {
  content: "\e973";
}

.iwp-icon-gen_del_empty_col:before {
  content: "\e948";
}

.iwp-icon-gen_delete:before {
  content: "\e949";
}

.iwp-icon-gen_detail_search:before {
  content: "\e94a";
}

.iwp-icon-gen_down:before {
  content: "\eb7b";
}

.iwp-icon-gen_download:before {
  content: "\ea3c";
}

.iwp-icon-gen_edit:before {
  content: "\e94b";
}

.iwp-icon-gen_edit_database:before {
  content: "\ead6";
}

.iwp-icon-gen_edit_doc:before {
  content: "\e9e8";
}

.iwp-icon-gen_edit_filter:before {
  content: "\ead7";
}

.iwp-icon-gen_edit_view:before {
  content: "\eaf4";
}

.iwp-icon-gen_edited_cell:before {
  content: "\eb55";
  color: #f8ab27;
}

.iwp-icon-gen_email:before {
  content: "\e94c";
}

.iwp-icon-gen_engine:before {
  content: "\e94d";
}

.iwp-icon-gen_error:before {
  content: "\ea06";
}

.iwp-icon-gen_export_data:before {
  content: "\eac4";
}

.iwp-icon-gen_export_excel:before {
  content: "\e91f";
}

.iwp-icon-gen_export_excel_r:before {
  content: "\e974";
}

.iwp-icon-gen_export_pdf:before {
  content: "\e98a";
}

.iwp-icon-gen_external_app:before {
  content: "\e951";
}

.iwp-icon-gen_favorite:before {
  content: "\e952";
}

.iwp-icon-gen_favorite_active:before {
  content: "\e9d4";
}

.iwp-icon-gen_favorite_half:before {
  content: "\ea7d";
}

.iwp-icon-gen_file_upload:before {
  content: "\e935";
}

.iwp-icon-gen_fill:before {
  content: "\e953";
}

.iwp-icon-gen_filter:before {
  content: "\e954";
}

.iwp-icon-gen_filter_add:before {
  content: "\e98b";
}

.iwp-icon-gen_filter_empty_rows:before {
  content: "\e800";
}

.iwp-icon-gen_filter_reset:before {
  content: "\e9c9";
}

.iwp-icon-gen_fit:before {
  content: "\e9b9";
}

.iwp-icon-gen_flag:before {
  content: "\ea47";
}

.iwp-icon-gen_followup:before {
  content: "\eafc";
}

.iwp-icon-gen_fullscreen:before {
  content: "\e955";
}

.iwp-icon-gen_globe:before {
  content: "\ea66";
}

.iwp-icon-gen_goto:before {
  content: "\e956";
}

.iwp-icon-gen_graphics:before {
  content: "\e957";
}

.iwp-icon-gen_guided_tour:before {
  content: "\e936";
}

.iwp-icon-gen_handshake:before {
  content: "\ea3d";
}

.iwp-icon-gen_harvey_empty:before {
  content: "\ebcc";
}

.iwp-icon-gen_harvey_full:before {
  content: "\ebcd";
}

.iwp-icon-gen_harvey_half:before {
  content: "\ebce";
}

.iwp-icon-gen_harvey_oneq:before {
  content: "\ebcf";
}

.iwp-icon-gen_harvey_threeq:before {
  content: "\ebd2";
}

.iwp-icon-gen_help:before {
  content: "\e958";
}

.iwp-icon-gen_hide:before {
  content: "\eb58";
}

.iwp-icon-gen_hierarchy_list_collapse:before {
  content: "\e959";
}

.iwp-icon-gen_hierarchy_list_expand:before {
  content: "\e95a";
}

.iwp-icon-gen_highlight_in_grid:before {
  content: "\e95b";
}

.iwp-icon-gen_history:before {
  content: "\ea11";
}

.iwp-icon-gen_history_delete:before {
  content: "\ebbd";
}

.iwp-icon-gen_home:before {
  content: "\e9ce";
}

.iwp-icon-gen_hourglass:before {
  content: "\e95c";
}

.iwp-icon-gen_image:before {
  content: "\e805";
}

.iwp-icon-gen_import_data:before {
  content: "\ea48";
}

.iwp-icon-gen_in_progress:before {
  content: "\e9d3";
}

.iwp-icon-gen_info:before {
  content: "\e95d";
}

.iwp-icon-gen_info_error:before {
  content: "\e801";
  color: #c33;
}

.iwp-icon-gen_info_info:before {
  content: "\e802";
  color: #90a2bd;
}

.iwp-icon-gen_info_ok:before {
  content: "\e9d8";
  color: #a2ad00;
}

.iwp-icon-gen_info_pause:before {
  content: "\ea08";
  color: #fecb00;
}

.iwp-icon-gen_info_play:before {
  content: "\ea07";
  color: #a2ad00;
}

.iwp-icon-gen_info_warning:before {
  content: "\e803";
  color: #e29205;
}

.iwp-icon-gen_help:before {
  content: "\e958";
}

.iwp-icon-gen_instruction:before {
  content: "\eafd";
}

.iwp-icon-gen_key:before {
  content: "\eaad";
}

.iwp-icon-gen_kontextmenu:before {
  content: "\e923";
}

.iwp-icon-gen_lang_de:before {
  content: "\e95e";
}

.iwp-icon-gen_lang_en:before {
  content: "\e95f";
}

.iwp-icon-gen_layoutmanager:before {
  content: "\e960";
}

.iwp-icon-gen_left_down:before {
  content: "\eb13";
}

.iwp-icon-gen_left_up:before {
  content: "\eb14";
}

.iwp-icon-gen_link:before {
  content: "\e961";
}

.iwp-icon-gen_linked_docs:before {
  content: "\e962";
}

.iwp-icon-gen_locked:before {
  content: "\e963";
}

.iwp-icon-gen_log:before {
  content: "\ea85";
}

.iwp-icon-gen_logout:before {
  content: "\e9e9";
}

.iwp-icon-gen_lync:before {
  content: "\e964";
}

.iwp-icon-gen_manual:before {
  content: "\e9d9";
}

.iwp-icon-gen_menu:before {
  content: "\e965";
}

.iwp-icon-gen_merge:before {
  content: "\ebd4";
}

.iwp-icon-gen_message:before {
  content: "\e966";
}

.iwp-icon-gen_minimize_size:before {
  content: "\e920";
}

.iwp-icon-gen_minus:before {
  content: "\e967";
}

.iwp-icon-gen_mobile:before {
  content: "\ea67";
}

.iwp-icon-gen_monitor:before {
  content: "\ea68";
}

.iwp-icon-gen_more:before {
  content: "\ea22";
}

.iwp-icon-gen_motorcycle:before {
  content: "\e937";
}

.iwp-icon-gen_nael:before {
  content: "\ea15";
}

.iwp-icon-gen_nav_arrow_down:before {
  content: "\e968";
}

.iwp-icon-gen_nav_arrow_left:before {
  content: "\e969";
}

.iwp-icon-gen_nav_arrow_right:before {
  content: "\e96a";
}

.iwp-icon-gen_nav_arrow_up:before {
  content: "\e96b";
}

.iwp-icon-gen_nav_back:before {
  content: "\e96c";
}

.iwp-icon-gen_nav_backward:before {
  content: "\ea20";
}

.iwp-icon-gen_nav_end:before {
  content: "\e96d";
}

.iwp-icon-gen_nav_forward:before {
  content: "\ea21";
}

.iwp-icon-gen_nav_next:before {
  content: "\e96e";
}

.iwp-icon-gen_nav_start:before {
  content: "\e96f";
}

.iwp-icon-gen_negative_value:before {
  content: "\ebd5";
}

.iwp-icon-gen_new:before {
  content: "\e9ea";
}

.iwp-icon-gen_next:before {
  content: "\e9eb";
}

.iwp-icon-gen_number:before {
  content: "\eb5f";
}

.iwp-icon-gen_ok:before {
  content: "\e970";
}

.iwp-icon-gen_paste:before {
  content: "\e971";
}

.iwp-icon-gen_percent:before {
  content: "\ea1d";
}

.iwp-icon-gen_person_info:before {
  content: "\e972";
}

.iwp-icon-gen_pin:before {
  content: "\e975";
}

.iwp-icon-gen_pin_active:before {
  content: "\e939";
}

.iwp-icon-gen_pkw:before {
  content: "\ea12";
}

.iwp-icon-gen_plus:before {
  content: "\e976";
}

.iwp-icon-gen_previous:before {
  content: "\e9ec";
}

.iwp-icon-gen_print:before {
  content: "\e977";
}

.iwp-icon-gen_priority:before {
  content: "\ea54";
}

.iwp-icon-gen_process:before {
  content: "\eac6";
}

.iwp-icon-gen_radiobtn_checked:before {
  content: "\e924";
}

.iwp-icon-gen_radiobtn_unchecked:before {
  content: "\e925";
}

.iwp-icon-gen_redo:before {
  content: "\eac2";
}

.iwp-icon-gen_refresh:before {
  content: "\e978";
}

.iwp-icon-gen_remove_filter:before {
  content: "\e804";
}

.iwp-icon-gen_repeat:before {
  content: "\eb11";
}

.iwp-icon-gen_report_datacheck:before {
  content: "\eb60";
}

.iwp-icon-gen_reverse:before {
  content: "\eb61";
}

.iwp-icon-gen_right_down:before {
  content: "\eb10";
}

.iwp-icon-gen_right_up:before {
  content: "\eb12";
}

.iwp-icon-gen_role:before {
  content: "\e9ed";
}

.iwp-icon-gen_run:before {
  content: "\e979";
}

.iwp-icon-gen_salapa:before {
  content: "\e9cf";
}

.iwp-icon-gen_save:before {
  content: "\e97a";
}

.iwp-icon-gen_screenshot:before {
  content: "\e97b";
}

.iwp-icon-gen_search:before {
  content: "\e97c";
}

.iwp-icon-gen_select_all:before {
  content: "\eb8b";
}

.iwp-icon-gen_select_rows:before {
  content: "\ebbe";
}

.iwp-icon-gen_settings:before {
  content: "\e97d";
}

.iwp-icon-gen_share:before {
  content: "\e97e";
}

.iwp-icon-gen_shopping_cart:before {
  content: "\e9da";
}

.iwp-icon-gen_show_all:before {
  content: "\ea55";
}

.iwp-icon-gen_show_error_warning:before {
  content: "\e9ae";
}

.iwp-icon-gen_snapshot:before {
  content: "\ea1e";
}

.iwp-icon-gen_sort:before {
  content: "\e97f";
}

.iwp-icon-gen_sort_asc:before {
  content: "\e980";
}

.iwp-icon-gen_sort_desc:before {
  content: "\e981";
}

.iwp-icon-gen_sort_list_asc:before {
  content: "\e982";
}

.iwp-icon-gen_sort_list_desc:before {
  content: "\e983";
}

.iwp-icon-gen_special_orders:before {
  content: "\ebe0";
}

.iwp-icon-gen_split_bottom:before {
  content: "\e984";
}

.iwp-icon-gen_split_close:before {
  content: "\e9ee";
}

.iwp-icon-gen_split_left:before {
  content: "\e985";
}

.iwp-icon-gen_split_right:before {
  content: "\e986";
}

.iwp-icon-gen_split_top:before {
  content: "\e987";
}

.iwp-icon-gen_split_triple:before {
  content: "\e9db";
}

.iwp-icon-gen_statement:before {
  content: "\eaff";
}

.iwp-icon-gen_statement_notice:before {
  content: "\eafe";
}

.iwp-icon-gen_status_delete:before {
  content: "\ea24";
}

.iwp-icon-gen_status_lights:before {
  content: "\e9af";
}

.iwp-icon-gen_status_lock:before {
  content: "\ea25";
}

.iwp-icon-gen_status_new:before {
  content: "\e9ef";
  color: #fecb00;
}

.iwp-icon-gen_stop:before {
  content: "\e9b8";
}

.iwp-icon-gen_subtask_done:before {
  content: "\ebdb";
}

.iwp-icon-gen_subtask_open:before {
  content: "\ebdc";
}

.iwp-icon-gen_sum:before {
  content: "\eb62";
}

.iwp-icon-gen_sum_cumulative:before {
  content: "\ebbf";
}

.iwp-icon-gen_sum_time_frame:before {
  content: "\ebc0";
}

.iwp-icon-gen_table:before {
  content: "\ea0e";
}

.iwp-icon-gen_tag:before {
  content: "\eac8";
}

.iwp-icon-gen_take_from_to:before {
  content: "\e9b0";
}

.iwp-icon-gen_task_done:before {
  content: "\ebdd";
}

.iwp-icon-gen_task_open:before {
  content: "\ebde";
}

.iwp-icon-gen_text_doc:before {
  content: "\e9b1";
}

.iwp-icon-gen_ticket:before {
  content: "\e9dc";
}

.iwp-icon-gen_time:before {
  content: "\e988";
}

.iwp-icon-gen_tire:before {
  content: "\eb82";
}

.iwp-icon-gen_token:before {
  content: "\ea69";
}

.iwp-icon-gen_typschluessel:before {
  content: "\e9b2";
}

.iwp-icon-gen_typschluessel_add:before {
  content: "\e9e4";
}

.iwp-icon-gen_undelete:before {
  content: "\eac5";
}

.iwp-icon-gen_under_construction:before {
  content: "\ea44";
}

.iwp-icon-gen_undo:before {
  content: "\e989";
}

.iwp-icon-gen_undo_all:before {
  content: "\ea49";
}

.iwp-icon-gen_unlocked:before {
  content: "\e9f0";
}

.iwp-icon-gen_up:before {
  content: "\eb7c";
}

.iwp-icon-gen_upload:before {
  content: "\e94e";
}

.iwp-icon-gen_user:before {
  content: "\e9f1";
}

.iwp-icon-gen_user_add:before {
  content: "\e9f2";
}

.iwp-icon-gen_user_filled:before {
  content: "\ea75";
}

.iwp-icon-gen_usersettings:before {
  content: "\eac9";
}

.iwp-icon-gen_vis_part:before {
  content: "\e98c";
}

.iwp-icon-gen_warning:before {
  content: "\e98d";
}

.iwp-icon-gen_weight:before {
  content: "\e98e";
}

.iwp-icon-gen_wheel:before {
  content: "\eb83";
}

.iwp-icon-gen_world:before {
  content: "\eaae";
}

.iwp-icon-gen_xml:before {
  content: "\ea29";
}

.iwp-icon-gen_zoom_in:before {
  content: "\e9ba";
}

.iwp-icon-gen_zoom_out:before {
  content: "\e9c7";
}

.iwp-icon-geotdm:before {
  content: "\eac7";
}

.iwp-icon-gewichtsberechnung:before {
  content: "\e9b5";
}

.iwp-icon-gewichtspflege:before {
  content: "\e9b6";
}

.iwp-icon-gewichtstabelle:before {
  content: "\e9b7";
}

.iwp-icon-groupadmin:before {
  content: "\e9f3";
}

.iwp-icon-groupadmin_group_add:before {
  content: "\e9f4";
}

.iwp-icon-groupadmin_my_group:before {
  content: "\e9f5";
}

.iwp-icon-groupadmin_team:before {
  content: "\ea0b";
}

.iwp-icon-homogen:before {
  content: "\eb7e";
}

.iwp-icon-hws:before {
  content: "\ebca";
}

.iwp-icon-ih-createmalf:before {
  content: "\ea4a";
}

.iwp-icon-ih-equipchange:before {
  content: "\ea4b";
}

.iwp-icon-ih-mymaint:before {
  content: "\ea4c";
}

.iwp-icon-ih-mymalf:before {
  content: "\ea4d";
}

.iwp-icon-ih-sparepart:before {
  content: "\ea4e";
}

.iwp-icon-ih-teammaint:before {
  content: "\ea4f";
}

.iwp-icon-ih-teammalf:before {
  content: "\ea50";
}

.iwp-icon-ih-tohistory:before {
  content: "\ea51";
}

.iwp-icon-ijix:before {
  content: "\eb90";
}

.iwp-icon-ijix_maintain_part_family:before {
  content: "\eb95";
}

.iwp-icon-ijix_maintain_part_number:before {
  content: "\eb96";
}

.iwp-icon-ijix_master_view_calloff:before {
  content: "\eba9";
}

.iwp-icon-ijix_master_view_tad:before {
  content: "\ebaa";
}

.iwp-icon-ijix_monitoring_part_family:before {
  content: "\ebab";
}

.iwp-icon-ijix_monitoring_supply_control:before {
  content: "\eb97";
}

.iwp-icon-ipdm_dashboard:before {
  content: "\ead3";
}

.iwp-icon-iram:before {
  content: "\e9f6";
}

.iwp-icon-iram_classification:before {
  content: "\ea18";
}

.iwp-icon-iram_clip:before {
  content: "\ea52";
}

.iwp-icon-iram_late_release:before {
  content: "\eb98";
}

.iwp-icon-iram_logistics:before {
  content: "\ea16";
}

.iwp-icon-iram_milestone:before {
  content: "\ebcb";
}

.iwp-icon-iram_milestone_reset:before {
  content: "\ebd6";
}

.iwp-icon-iram_termination:before {
  content: "\ea3e";
}

.iwp-icon-iwp_add_app:before {
  content: "\e98f";
}

.iwp-icon-iwp_dashboard:before {
  content: "\e990";
}

.iwp-icon-iwp_dashboard_add:before {
  content: "\e9f7";
}

.iwp-icon-iwp_excel:before {
  content: "\ea0d";
  color: #217446;
}

.iwp-icon-iwp_pdf:before {
  content: "\e9dd";
  color: #d42839;
}

.iwp-icon-iwp_powerpoint:before {
  content: "\e9de";
  color: #d14524;
}

.iwp-icon-iwp_rectangle:before {
  content: "\e9df";
}

.iwp-icon-iwp_resize_widget:before {
  content: "\ea2f";
}

.iwp-icon-iwp_share:before {
  content: "\e9f8";
}

.iwp-icon-iwp_share_owner:before {
  content: "\ea04";
}

.iwp-icon-iwp_share_participant:before {
  content: "\ea05";
}

.iwp-icon-iwp_square:before {
  content: "\e9e0";
}

.iwp-icon-iwp_task:before {
  content: "\e991";
}

.iwp-icon-iwp_weblink:before {
  content: "\e9e1";
  color: #6c7c97;
}

.iwp-icon-iwp_word:before {
  content: "\e9e2";
  color: #2a5598;
}

.iwp-icon-jims:before {
  content: "\ebad";
}

.iwp-icon-js_import:before {
  content: "\ea9b";
}

.iwp-icon-js_structure_export:before {
  content: "\ea98";
}

.iwp-icon-js_structure_publishing:before {
  content: "\ea97";
}

.iwp-icon-js_teamcenter:before {
  content: "\ea9a";
}

.iwp-icon-les:before {
  content: "\eb8c";
}

.iwp-icon-light_derivatsauslegung:before {
  content: "\e9bb";
}

.iwp-icon-light_derivatsauslegung_neu:before {
  content: "\e9d0";
}

.iwp-icon-light_gams_fortschreibungsrel:before {
  content: "\e9bc";
}

.iwp-icon-light_gebietsrechenregelversion:before {
  content: "\e9bd";
}

.iwp-icon-light_lastfalldefinition:before {
  content: "\e9be";
}

.iwp-icon-light_open_config:before {
  content: "\e9bf";
}

.iwp-icon-light_rechenregel:before {
  content: "\e9c0";
}

.iwp-icon-light_search:before {
  content: "\e9c1";
}

.iwp-icon-light_select_all_in_grid:before {
  content: "\e9ac";
}

.iwp-icon-light_select_lines_in_grid:before {
  content: "\e9ad";
}

.iwp-icon-light_status10_temp:before {
  content: "\e9c4";
  color: #a469ce;
}

.iwp-icon-light_status12_assessment:before {
  content: "\e9c5";
  color: #a3b41e;
}

.iwp-icon-light_status18_berichtsystemefr:before {
  content: "\e9c6";
  color: #a1af00;
}

.iwp-icon-light_status_freigegeben:before {
  content: "\e9c2";
  color: #a1af00;
}

.iwp-icon-light_status_gesamtfr:before {
  content: "\e9c3";
  color: #a1af00;
}

.iwp-icon-loki:before {
  content: "\eb57";
}

.iwp-icon-maestro:before {
  content: "\ebc5";
}

.iwp-icon-matrx:before {
  content: "\eb7f";
}

.iwp-icon-ministamm:before {
  content: "\ea0f";
}

.iwp-icon-mqm:before {
  content: "\e992";
}

.iwp-icon-mwp:before {
  content: "\eaca";
}

.iwp-icon-mwp_admin:before {
  content: "\eacb";
}

.iwp-icon-mwp_apps:before {
  content: "\eacc";
}

.iwp-icon-mwp_catalog:before {
  content: "\eacd";
}

.iwp-icon-mwp_taskfilter_assigned:before {
  content: "\eace";
}

.iwp-icon-mwp_taskfilter_created:before {
  content: "\eacf";
}

.iwp-icon-mwp_taskfilter_delegated:before {
  content: "\eb9d";
}

.iwp-icon-mwp_taskfilter_private:before {
  content: "\ead0";
}

.iwp-icon-mwp_widgetfilter:before {
  content: "\ead1";
}

.iwp-icon-mynaels:before {
  content: "\ea6a";
}

.iwp-icon-nat:before {
  content: "\ea95";
}

.iwp-icon-nextone:before {
  content: "\eb07";
}

.iwp-icon-nextone_task:before {
  content: "\eb08";
}

.iwp-icon-onl:before {
  content: "\eaa8";
}

.iwp-icon-onl_add_price:before {
  content: "\eaf9";
}

.iwp-icon-onl_delete_pice:before {
  content: "\eafa";
}

.iwp-icon-onl_nav_masterdata:before {
  content: "\eab3";
}

.iwp-icon-open_dxm:before {
  content: "\eb52";
}

.iwp-icon-optibe:before {
  content: "\eb99";
}

.iwp-icon-orcan:before {
  content: "\ebe6";
}

.iwp-icon-otd:before {
  content: "\e993";
}

.iwp-icon-otd2:before {
  content: "\e994";
}

.iwp-icon-otd_add_vehicle:before {
  content: "\ea2b";
}

.iwp-icon-otd_data_edit:before {
  content: "\ea2a";
}

.iwp-icon-otd_field_deleted:before {
  content: "\ea31";
}

.iwp-icon-otd_field_unassigned:before {
  content: "\ea32";
}

.iwp-icon-otd_field_unknown:before {
  content: "\ea33";
}

.iwp-icon-otd_formula_field:before {
  content: "\ea35";
}

.iwp-icon-otd_gate_closed:before {
  content: "\ea43";
}

.iwp-icon-otd_link_auto:before {
  content: "\ea28";
}

.iwp-icon-otd_link_manual:before {
  content: "\ea27";
}

.iwp-icon-otd_missing_gate:before {
  content: "\ea36";
}

.iwp-icon-otd_missing_permission:before {
  content: "\ea37";
}

.iwp-icon-otd_no_edit:before {
  content: "\ea2e";
}

.iwp-icon-otd_time_value_dif:before {
  content: "\ea2c";
}

.iwp-icon-otd_wrong_process:before {
  content: "\ea38";
}

.iwp-icon-pc:before {
  content: "\e806";
}

.iwp-icon-pc_case:before {
  content: "\ea3f";
}

.iwp-icon-pc_info_warning:before {
  content: "\ea09";
  color: #c33;
}

.iwp-icon-pc_iram_info:before {
  content: "\ea39";
}

.iwp-icon-pc_process:before {
  content: "\ea01";
}

.iwp-icon-pc_process_overview:before {
  content: "\e807";
}

.iwp-icon-pc_task_stock:before {
  content: "\ea02";
}

.iwp-icon-pluto:before {
  content: "\ea7c";
}

.iwp-icon-pluto_add_row_after:before {
  content: "\eae3";
}

.iwp-icon-pluto_add_row_before:before {
  content: "\eae4";
}

.iwp-icon-pluto_alternative_semifinished_product:before {
  content: "\ebc6";
}

.iwp-icon-pluto_assign_semifinished_product_number:before {
  content: "\ebc7";
}

.iwp-icon-pluto_blank_-rectangle:before {
  content: "\eae5";
}

.iwp-icon-pluto_blank_arc:before {
  content: "\ebd7";
}

.iwp-icon-pluto_blank_form:before {
  content: "\eae6";
}

.iwp-icon-pluto_blank_trapez:before {
  content: "\eae7";
}

.iwp-icon-pluto_bw_admin:before {
  content: "\eae8";
}

.iwp-icon-pluto_bw_control:before {
  content: "\eae9";
}

.iwp-icon-pluto_calendarization_curves_standard:before {
  content: "\eb84";
}

.iwp-icon-pluto_caption:before {
  content: "\eaea";
}

.iwp-icon-pluto_checking_fixture:before {
  content: "\eb9e";
}

.iwp-icon-pluto_checking_fixture_plan:before {
  content: "\ebdf";
}

.iwp-icon-pluto_checking_fixture_status:before {
  content: "\eb9f";
}

.iwp-icon-pluto_checking_plan:before {
  content: "\eba0";
}

.iwp-icon-pluto_checking_plan_status:before {
  content: "\eba1";
}

.iwp-icon-pluto_coil:before {
  content: "\eaeb";
}

.iwp-icon-pluto_container:before {
  content: "\eb9a";
}

.iwp-icon-pluto_container_status:before {
  content: "\eb9b";
}

.iwp-icon-pluto_delete_row:before {
  content: "\eaec";
}

.iwp-icon-pluto_final_state_part:before {
  content: "\eaed";
}

.iwp-icon-pluto_focus_element:before {
  content: "\eaee";
}

.iwp-icon-pluto_group:before {
  content: "\eaef";
}

.iwp-icon-pluto_intermediate_state:before {
  content: "\eaf0";
}

.iwp-icon-pluto_klv_ps:before {
  content: "\eaf1";
}

.iwp-icon-pluto_km_tool:before {
  content: "\eb85";
}

.iwp-icon-pluto_material_price_premisses:before {
  content: "\eaf2";
}

.iwp-icon-pluto_peu:before {
  content: "\ebae";
}

.iwp-icon-pluto_press_fits_elements:before {
  content: "\eaf3";
}

.iwp-icon-pluto_product_invest:before {
  content: "\ead8";
}

.iwp-icon-pluto_product_invest_ext:before {
  content: "\ebd8";
}

.iwp-icon-pluto_product_invest_int:before {
  content: "\ebd9";
}

.iwp-icon-pluto_production_cost:before {
  content: "\ead9";
}

.iwp-icon-pluto_production_vs_invest:before {
  content: "\eb86";
}

.iwp-icon-pluto_project_calendarization:before {
  content: "\eb87";
}

.iwp-icon-pluto_project_serie:before {
  content: "\eada";
}

.iwp-icon-pluto_psp_calendarization:before {
  content: "\eb8f";
}

.iwp-icon-pluto_redo_release:before {
  content: "\eadc";
}

.iwp-icon-pluto_save_and_release:before {
  content: "\eadd";
}

.iwp-icon-pluto_semifinished_product:before {
  content: "\ebac";
}

.iwp-icon-pluto_semifinished_product_ordering:before {
  content: "\ebc8";
}

.iwp-icon-pluto_semifinished_product_status:before {
  content: "\ebaf";
}

.iwp-icon-pluto_set:before {
  content: "\eade";
}

.iwp-icon-pluto_si_calendarization:before {
  content: "\eba4";
}

.iwp-icon-pluto_sort:before {
  content: "\eadf";
}

.iwp-icon-pluto_splitview_serie:before {
  content: "\eae0";
}

.iwp-icon-pluto_structure_invest:before {
  content: "\eb91";
}

.iwp-icon-pluto_tube:before {
  content: "\eae1";
}

.iwp-icon-pluto_valuation_run:before {
  content: "\eadb";
}

.iwp-icon-pluto_workposition:before {
  content: "\eae2";
}

.iwp-icon-pluto_wppro_optimize:before {
  content: "\ebc9";
}

.iwp-icon-pmc:before {
  content: "\e995";
}

.iwp-icon-pms:before {
  content: "\eab4";
}

.iwp-icon-pms_add_price_rule:before {
  content: "\eaf5";
}

.iwp-icon-pms_add_usage:before {
  content: "\eab5";
}

.iwp-icon-pms_approve:before {
  content: "\eab9";
}

.iwp-icon-pms_approve_change:before {
  content: "\eaba";
}

.iwp-icon-pms_decline:before {
  content: "\eabb";
}

.iwp-icon-pms_delete_price_rule:before {
  content: "\eaf6";
}

.iwp-icon-pms_favorite_manage:before {
  content: "\eabc";
}

.iwp-icon-pms_pcheck:before {
  content: "\eabd";
}

.iwp-icon-pms_ready:before {
  content: "\eabe";
}

.iwp-icon-pms_reject_change:before {
  content: "\eabf";
}

.iwp-icon-pms_set_z_price_sel:before {
  content: "\eac0";
}

.iwp-icon-pms_z_set_price_all:before {
  content: "\eac1";
}

.iwp-icon-pno_autom_time_series_parameter:before {
  content: "\eb63";
}

.iwp-icon-pno_calendar_next:before {
  content: "\eb64";
}

.iwp-icon-pno_car_placer:before {
  content: "\eb65";
}

.iwp-icon-pno_column_collapse:before {
  content: "\eb66";
}

.iwp-icon-pno_column_expand:before {
  content: "\eb67";
}

.iwp-icon-pno_column_selection:before {
  content: "\eb68";
}

.iwp-icon-pno_create_downtime:before {
  content: "\ebda";
}

.iwp-icon-pno_delta_absolute_values:before {
  content: "\eb6a";
}

.iwp-icon-pno_delta_percent:before {
  content: "\eb6b";
}

.iwp-icon-pno_detail_view:before {
  content: "\eb6c";
}

.iwp-icon-pno_effektive_takerates:before {
  content: "\eb6d";
}

.iwp-icon-pno_hide_zero:before {
  content: "\eb6e";
}

.iwp-icon-pno_maintain_classes:before {
  content: "\eb6f";
}

.iwp-icon-pno_manage_baselines:before {
  content: "\eb70";
}

.iwp-icon-pno_product_country_mix:before {
  content: "\eb71";
}

.iwp-icon-pno_scrollbar:before {
  content: "\eb7d";
}

.iwp-icon-pno_seasonal_distribution:before {
  content: "\eb72";
}

.iwp-icon-pno_set_productive:before {
  content: "\eb73";
}

.iwp-icon-pno_show_downtime:before {
  content: "\ebe3";
}

.iwp-icon-pno_show_halfyear:before {
  content: "\eb74";
}

.iwp-icon-pno_show_month:before {
  content: "\eb75";
}

.iwp-icon-pno_show_quarter:before {
  content: "\eb76";
}

.iwp-icon-pno_show_week:before {
  content: "\eb77";
}

.iwp-icon-pno_show_year:before {
  content: "\ebd3";
}

.iwp-icon-pps_accessories:before {
  content: "\eb78";
}

.iwp-icon-pps_attribute:before {
  content: "\eb53";
}

.iwp-icon-pps_attribute_set:before {
  content: "\eb54";
}

.iwp-icon-pps_bundling:before {
  content: "\eb0f";
}

.iwp-icon-pps_clala:before {
  content: "\eaf7";
}

.iwp-icon-pps_ganttchart:before {
  content: "\ead5";
}

.iwp-icon-pps_lifestyle:before {
  content: "\eaf8";
}

.iwp-icon-pps_relation:before {
  content: "\eab8";
}

.iwp-icon-pps_value_dependent_node:before {
  content: "\ebe7";
}

.iwp-icon-pqm:before {
  content: "\e996";
}

.iwp-icon-pqm_task:before {
  content: "\eb09";
}

.iwp-icon-pri_d_letter:before {
  content: "\e997";
}

.iwp-icon-pri_doc:before {
  content: "\e9d1";
}

.iwp-icon-pri_doc_background:before {
  content: "\e998";
}

.iwp-icon-pri_doc_outline:before {
  content: "\e999";
}

.iwp-icon-pri_folder_outline:before {
  content: "\e99a";
}

.iwp-icon-pri_st_letters:before {
  content: "\e99b";
}

.iwp-icon-pri_vers_background:before {
  content: "\e99c";
}

.iwp-icon-pri_vers_outline-08:before {
  content: "\e99d";
}

.iwp-icon-pri_vers_outline-10:before {
  content: "\e99e";
}

.iwp-icon-pri_vers_x:before {
  content: "\e99f";
}

.iwp-icon-pri_vers_x_outline:before {
  content: "\e9a0";
}

.iwp-icon-pri_version:before {
  content: "\e9d2";
}

.iwp-icon-prisma:before {
  content: "\ea6b";
}

.iwp-icon-prisma_add_user:before {
  content: "\ea6c";
}

.iwp-icon-prisma_approval_status:before {
  content: "\ea6d";
}

.iwp-icon-prisma_approve_rights:before {
  content: "\ea6e";
}

.iwp-icon-prisma_asp:before {
  content: "\eaa5";
}

.iwp-icon-prisma_competition:before {
  content: "\ea6f";
}

.iwp-icon-prisma_create:before {
  content: "\ea71";
}

.iwp-icon-prisma_doc:before {
  content: "\ead2";
}

.iwp-icon-prisma_fast_creation:before {
  content: "\ea70";
}

.iwp-icon-prisma_favorites:before {
  content: "\ea76";
}

.iwp-icon-prisma_ff:before {
  content: "\ea77";
}

.iwp-icon-prisma_import_plm_xml:before {
  content: "\ea78";
}

.iwp-icon-prisma_lova:before {
  content: "\ea79";
}

.iwp-icon-prisma_message_profile:before {
  content: "\ea7a";
}

.iwp-icon-prisma_messages:before {
  content: "\ea7b";
}

.iwp-icon-prisma_nbs:before {
  content: "\ea72";
}

.iwp-icon-prisma_personal_rights:before {
  content: "\ea83";
}

.iwp-icon-prisma_ppg_responsibility:before {
  content: "\ea84";
}

.iwp-icon-prisma_ppg_take_over:before {
  content: "\ea73";
}

.iwp-icon-prisma_profiles:before {
  content: "\ea8a";
}

.iwp-icon-prisma_project:before {
  content: "\ea8b";
}

.iwp-icon-prisma_project_rights:before {
  content: "\ea8c";
}

.iwp-icon-prisma_rights_management:before {
  content: "\ea8d";
}

.iwp-icon-prisma_rule:before {
  content: "\ea8e";
}

.iwp-icon-prisma_search:before {
  content: "\ea8f";
}

.iwp-icon-prisma_settings:before {
  content: "\ea90";
}

.iwp-icon-prisma_user_admin:before {
  content: "\ea91";
}

.iwp-icon-prisma_work_in_ventory:before {
  content: "\ea74";
}

.iwp-icon-proflex:before {
  content: "\ea34";
}

.iwp-icon-proflex_karma:before {
  content: "\ea53";
}

.iwp-icon-proflex_pima:before {
  content: "\ea40";
}

.iwp-icon-proflex_repika:before {
  content: "\ea2d";
}

.iwp-icon-proflex_szma:before {
  content: "\ea45";
}

.iwp-icon-proflex_vofapl:before {
  content: "\ea99";
}

.iwp-icon-q_search:before {
  content: "\e9a1";
}

.iwp-icon-qmt_task:before {
  content: "\eb0a";
}

.iwp-icon-qserv:before {
  content: "\ea94";
}

.iwp-icon-ready2supply:before {
  content: "\eb80";
}

.iwp-icon-redint:before {
  content: "\ea96";
}

.iwp-icon-responsibility:before {
  content: "\eba2";
}

.iwp-icon-responsibility_edit:before {
  content: "\eba3";
}

.iwp-icon-rima:before {
  content: "\eb8a";
}

.iwp-icon-rrfd:before {
  content: "\ea19";
}

.iwp-icon-rrfd_concept:before {
  content: "\eb92";
}

.iwp-icon-rrfd_pressure:before {
  content: "\ea1a";
}

.iwp-icon-rrfd_rowi:before {
  content: "\eb93";
}

.iwp-icon-rrfd_sign:before {
  content: "\ea1b";
}

.iwp-icon-rrfd_tire_wheel:before {
  content: "\eb94";
}

.iwp-icon-sap_changenumber:before {
  content: "\eaa6";
}

.iwp-icon-sap_document:before {
  content: "\eaa7";
}

.iwp-icon-sap_generic_node:before {
  content: "\eaaf";
}

.iwp-icon-sap_ipp:before {
  content: "\eab0";
}

.iwp-icon-sap_settings:before {
  content: "\eab1";
}

.iwp-icon-sap_structure_maintenance:before {
  content: "\eab2";
}

.iwp-icon-sap_structure_maintenance_favorite:before {
  content: "\eab6";
}

.iwp-icon-sap_tais_web:before {
  content: "\eab7";
}

.iwp-icon-sprint:before {
  content: "\eb9c";
}

.iwp-icon-strategy-next:before {
  content: "\ea42";
}

.iwp-icon-tais_uss:before {
  content: "\eb79";
}

.iwp-icon-tako:before {
  content: "\ebe1";
}

.iwp-icon-tam:before {
  content: "\e9a2";
}

.iwp-icon-task_manager:before {
  content: "\ea46";
}

.iwp-icon-tbwc:before {
  content: "\e9f9";
}

.iwp-icon-tc_aera_edit:before {
  content: "\e9c8";
}

.iwp-icon-tc_bauphase_add:before {
  content: "\e9fa";
}

.iwp-icon-tc_filter_error:before {
  content: "\e9fb";
}

.iwp-icon-tc_pulk_add:before {
  content: "\e9fc";
}

.iwp-icon-tc_pulk_refresh:before {
  content: "\e9fd";
}

.iwp-icon-tc_release_add:before {
  content: "\e9fe";
}

.iwp-icon-tc_search_event_takeover:before {
  content: "\e9ff";
}

.iwp-icon-tc_show_aera:before {
  content: "\e9ca";
}

.iwp-icon-tc_show_pulk:before {
  content: "\e9cb";
}

.iwp-icon-tc_tutorial:before {
  content: "\e9cc";
}

.iwp-icon-tereg:before {
  content: "\eb0c";
}

.iwp-icon-termin_cockpit:before {
  content: "\e9a3";
}

.iwp-icon-termo:before {
  content: "\ea57";
}

.iwp-icon-tim:before {
  content: "\ebb0";
}

.iwp-icon-tpm_dashboard:before {
  content: "\eb0e";
}

.iwp-icon-twenII:before {
  content: "\e94f";
}

.iwp-icon-user_search:before {
  content: "\eb81";
}

.iwp-icon-vaas:before {
  content: "\e9a4";
}

.iwp-icon-vga:before {
  content: "\ea80";
}

.iwp-icon-vim:before {
  content: "\ea81";
}

.iwp-icon-vip:before {
  content: "\ea10";
}

.iwp-icon-vrm:before {
  content: "\e9a5";
}

.iwp-icon-wissensfinder_search:before {
  content: "\e9a6";
}

.iwp-icon-ws_admin:before {
  content: "\ea9c";
}

.iwp-icon-ws_kit:before {
  content: "\ea9e";
}

.iwp-icon-ws_testing:before {
  content: "\ea9d";
}

.iwp-icon-zeb_chart_settings:before {
  content: "\e9a7";
}

.iwp-icon-zeb_folder:before {
  content: "\e9a8";
}

.iwp-icon-zeb_tab_setup:before {
  content: "\e9aa";
}

.iwp-icon-zebra:before {
  content: "\e9ab";
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.5;
}

.p-error, .p-invalid {
  color: #e4677e;
}

.p-text-secondary {
  color: #848484;
}

.pi {
  font-size: 24px;
}

.p-link {
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 0;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-autocomplete .p-autocomplete-loader {
  right: 8px;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 40px;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 4.5px 8px;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #2f2f2f;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 4.5px 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #444444;
  padding: 0;
  margin: 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 4.5px 8px;
  margin-right: 0.5rem;
  background: #7c89a1;
  color: #ffffff;
  border-radius: 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-error > .p-inputtext, .p-autocomplete.p-invalid > .p-inputtext {
  border-color: none;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: none;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #444444;
  border: 0 none;
  border-radius: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5em 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.4em 0.86em;
  border: 0 none;
  color: #444444;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #444444;
  background: #e9ebf1;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: none;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: none;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #444444;
  border: none;
  border-radius: 0;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #f8f9fa;
}
.p-datepicker .p-datepicker-header {
  padding: 0.2rem;
  color: #444444;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 14px;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.2rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #444444;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.286rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.429rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.2rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-checkbox {
  width: 16px;
  height: 16px;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid #c1c5cb;
  background: #f6f6f6;
  width: 16px;
  height: 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #fff;
  font-size: 10px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #6c7c97;
  background: #6c7c97;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #2f2f2f;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #6c7c97;
  background: #6c7c97;
  color: #fff;
}
.p-checkbox.p-error > .p-checkbox-box, .p-checkbox.p-invalid > .p-checkbox-box {
  border-color: none;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: none;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #ffffff;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #6c7c97;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #ffffff;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #6c7c97;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 4.5px 8px;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #2f2f2f;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 4.5px 8px;
  margin-right: 0.5rem;
  background: #7c89a1;
  color: #ffffff;
  border-radius: 0;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 4.5px 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #444444;
  padding: 0;
  margin: 0;
}
.p-chips.p-error > .p-inputtext, .p-chips.p-invalid > .p-inputtext {
  border-color: none;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: none;
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown {
  background: #f6f6f6;
  border: none;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #2f2f2f;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 32px;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #848484;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #444444;
  width: 2.357rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #444444;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #444444;
  border: 0 none;
  border-radius: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #444444;
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 32px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 8px;
  color: #444444;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5em 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.4em 0.86em;
  border: 0 none;
  color: #444444;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #444444;
  background: #e9ebf1;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.4em 0.86em;
  color: #444444;
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #444444;
  background: #ffffff;
  font-weight: 600;
}
.p-dropdown-panel.p-error, .p-dropdown-panel.p-invalid {
  border-color: none;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: none;
}

.p-input-filled .p-dropdown {
  background: #ffffff;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-editor-container .p-editor-toolbar {
  background: transparent;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: none;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #444444;
  padding: 0.4em 0.86em;
  border-radius: 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #444444;
  background: #e9ebf1;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #f6f6f6;
  color: #444444;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #7c89a1;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #7c89a1;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #7c89a1;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #7c89a1;
}

.p-inputgroup-addon {
  background: #ffffff;
  color: #848484;
  border-top: none;
  border-left: none;
  border-bottom: none;
  padding: 9px 8px;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: none;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 32px;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: none;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: none;
}

.p-inputswitch {
  width: 31px;
  height: 16px;
}
.p-inputswitch .p-inputswitch-slider {
  background: #b7b7b7;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 0.77rem;
  height: 0.77rem;
  left: 0.2rem;
  margin-top: -0.385rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(0.77rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #848484;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #7c89a1;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #6c7c97;
}
.p-inputswitch.p-error, .p-inputswitch.p-invalid {
  border-color: none;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: none;
}

.p-inputtext {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #444444;
  background: #f6f6f6;
  padding: 9px 8px;
  border: none;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 0;
}
.p-inputtext:enabled:hover {
  border-color: #2f2f2f;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-inputtext.p-error, .p-inputtext.p-invalid, .p-inputtext.ng-dirty.ng-invalid {
  border-color: none;
}
.p-inputtext.p-inputtext-sm {
  font-size: 12.25px;
  padding: 7.875px 7px;
}
.p-inputtext.p-inputtext-lg {
  font-size: 17.5px;
  padding: 11.25px 10px;
}

.p-float-label > label {
  left: 8px;
  color: #848484;
  transition-duration: 0.2s;
}

.p-input-icon-left > i:first-of-type {
  left: 8px;
  color: #444444;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 40px;
}

.p-input-icon-right > i:last-of-type {
  right: 8px;
  color: #444444;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 40px;
}

::-webkit-input-placeholder {
  color: #848484;
}

:-moz-placeholder {
  color: #848484;
}

::-moz-placeholder {
  color: #848484;
}

:-ms-input-placeholder {
  color: #848484;
}

.p-input-filled .p-inputtext {
  background-color: #ffffff;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #ffffff;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
  font-size: 12.25px;
  padding: 7.875px 7px;
}

.p-inputtext-lg .p-inputtext {
  font-size: 17.5px;
  padding: 11.25px 10px;
}

.p-listbox {
  background: #ffffff;
  color: #444444;
  border: none;
  border-radius: 0;
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #444444;
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 32px;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 8px;
  color: #444444;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5em 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.4em 0.86em;
  border: 0 none;
  color: #444444;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #444444;
  background: #e9ebf1;
}
.p-listbox.p-error, .p-listbox.p-invalid {
  border-color: none;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: none;
}

.p-multiselect {
  background: #f6f6f6;
  border: none;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #2f2f2f;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-multiselect .p-multiselect-label {
  padding: 9px 8px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #848484;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #444444;
  width: 2.357rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-multiselect.p-error, .p-multiselect.p-invalid {
  border-color: none;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #444444;
  border: 0 none;
  border-radius: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #444444;
  background: #ffffff;
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 32px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 8px;
  color: #444444;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5em 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.4em 0.86em;
  border: 0 none;
  color: #444444;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #444444;
  background: #e9ebf1;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.4em 0.86em;
  color: #444444;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: none;
}

.p-input-filled .p-multiselect {
  background: #ffffff;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

.p-password-panel {
  padding: 1rem;
  background: #ffffff;
  color: #444444;
  border: 1px solid #c8c8c8;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
}

.p-radiobutton {
  width: 16px;
  height: 16px;
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid #444444;
  background: #f6f6f6;
  width: 16px;
  height: 16px;
  color: #444444;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #2f2f2f;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #2f2f2f;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: #444444;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #444444;
  background: transparent;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #2f2f2f;
  background: transparent;
  color: #2f2f2f;
}
.p-radiobutton.p-error > .p-radiobutton-box, .p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: none;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #ffffff;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: transparent;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: transparent;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #444444;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #7c89a1;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #7c89a1;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #7c89a1;
  color: #7c89a1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #ffffff;
  border-color: #7c89a1;
  color: #7c89a1;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight {
  background: #7c89a1;
  border-color: #7c89a1;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #6c7c97;
  border-color: #7c89a1;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: none;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: none;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #7c89a1;
  color: #7c89a1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #ffffff;
  border-color: #7c89a1;
  color: #7c89a1;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight {
  background: #7c89a1;
  border-color: #7c89a1;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #6c7c97;
  border-color: #7c89a1;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-error, .p-togglebutton.p-button.p-invalid {
  border-color: none;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: none;
}

.p-button {
  color: #ffffff;
  background: #7c89a1;
  border: 1px solid #7c89a1;
  padding: 5px 5px;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-button:enabled:hover {
  background: #6c7c97;
  color: #ffffff;
  border-color: #6c7c97;
}
.p-button:enabled:active {
  background: #6c7c97;
  color: #ffffff;
  border-color: #6c7c97;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #7c89a1;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(124, 137, 161, 0.04);
  color: #7c89a1;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(124, 137, 161, 0.16);
  color: #7c89a1;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ebf1;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #7c89a1;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(124, 137, 161, 0.04);
  color: #7c89a1;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(124, 137, 161, 0.16);
  color: #7c89a1;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ebf1;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  color: #7c89a1;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.15);
}
.p-button.p-button-rounded {
  border-radius: 50%;
}
.p-button.p-button-icon-only {
  width: 32px;
  padding: 5px;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 32px;
}
.p-button.p-button-sm {
  font-size: 12.25px;
  padding: 4.375px 4.375px;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 21px;
}
.p-button.p-button-lg {
  font-size: 17.5px;
  padding: 6.25px 6.25px;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 30px;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 32px;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #444444;
  background: #ffffff;
  border: 1px solid #c2c2c2;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #ffffff;
  color: #6c7c97;
  border-color: #c2c2c2;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0 #e4e7ec;
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #ffffff;
  color: #6c7c97;
  border-color: #c2c2c2;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 255, 255, 0.04);
  color: #ffffff;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 255, 255, 0.16);
  color: #ffffff;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #ffffff;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 255, 255, 0.04);
  border-color: transparent;
  color: #ffffff;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(255, 255, 255, 0.16);
  border-color: transparent;
  color: #ffffff;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #007ad9;
  border: 1px solid #007ad9;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #116fbf;
  color: #ffffff;
  border-color: #116fbf;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #8dcdff;
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #005b9f;
  color: #ffffff;
  border-color: #005b9f;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #007ad9;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(0, 122, 217, 0.04);
  color: #007ad9;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(0, 122, 217, 0.16);
  color: #007ad9;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #007ad9;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(0, 122, 217, 0.04);
  border-color: transparent;
  color: #007ad9;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(0, 122, 217, 0.16);
  border-color: transparent;
  color: #007ad9;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #34a835;
  border: 1px solid #34a835;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #107d11;
  color: #ffffff;
  border-color: #107d11;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #aae5aa;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #0c6b0d;
  color: #ffffff;
  border-color: #0c6b0d;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #34a835;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(52, 168, 53, 0.04);
  color: #34a835;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(52, 168, 53, 0.16);
  color: #34a835;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #34a835;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(52, 168, 53, 0.04);
  border-color: transparent;
  color: #34a835;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(52, 168, 53, 0.16);
  border-color: transparent;
  color: #34a835;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #444444;
  background: #ffc107;
  border: 1px solid #ffc107;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #ffb300;
  color: #444444;
  border-color: #ffb300;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ffeeba;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #ffa000;
  color: #444444;
  border-color: #ffa000;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #ffc107;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: transparent;
  color: #ffc107;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  border-color: transparent;
  color: #ffc107;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9c27b0;
  border: 1px solid #9c27b0;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #8e24aa;
  color: #ffffff;
  border-color: #8e24aa;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ce93d8;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7b1fa2;
  color: #ffffff;
  border-color: #7b1fa2;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9c27b0;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9c27b0;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9c27b0;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #f44336;
  border: 1px solid #f44336;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #e53935;
  color: #ffffff;
  border-color: #e53935;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fde1df;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #d32f2f;
  color: #ffffff;
  border-color: #d32f2f;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f44336;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(244, 67, 54, 0.04);
  color: #f44336;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(244, 67, 54, 0.16);
  color: #f44336;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #f44336;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(244, 67, 54, 0.04);
  border-color: transparent;
  color: #f44336;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(244, 67, 54, 0.16);
  border-color: transparent;
  color: #f44336;
}

.p-button.p-button-link {
  color: #6c7c97;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #6c7c97;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem transparent;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #6c7c97;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #7c89a1;
  color: #ffffff;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #8f8f8f;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 12px 8px;
  border: 1px solid #c3beb0;
  border-width: 0 1px 0 0;
  font-weight: 200;
  color: #444444;
  background: #e0e0e0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  border-width: 0 0 1px 0;
  font-weight: 300;
  color: #444444;
  background: #ffffff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #c2c2c2;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #7c89a1;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e0e0e0;
  color: #444444;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #c2c2c2;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #e0e0e0;
  color: #444444;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #444444;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e0e0e0;
  color: #444444;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #444444;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem transparent;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #444444;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: transparent;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-width: 0 0 1px 0;
  padding: 4px 0px 4px 8px;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #7c89a1;
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #7c89a1;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #7c89a1;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #eaeaea;
  color: #444444;
}
.p-datatable .p-column-resizer-helper {
  background: #7c89a1;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #7c89a1;
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #ffffff;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 6px 4px;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 2px 0px;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 15px 10px;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 5px 0px;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #8f8f8f;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #444444;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.fc .fc-view-container th {
  background: #f8f9fa;
  border: 1.5px solid #d2dae3;
  color: #444444;
}
.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #444444;
}
.fc .fc-view-container td.fc-head-container {
  border: 1.5px solid #d2dae3;
}
.fc .fc-view-container .fc-row {
  border-right: 1.5px solid #d2dae3;
}
.fc .fc-view-container .fc-event {
  background: #6c7c97;
  border: 1px solid #6c7c97;
  color: #444444;
}
.fc .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1.5px solid #d2dae3;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #7c89a1;
  border: 1px solid #7c89a1;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
  display: flex;
  align-items: center;
}
.fc .fc-toolbar .fc-button:enabled:hover {
  background: #6c7c97;
  color: #ffffff;
  border-color: #6c7c97;
}
.fc .fc-toolbar .fc-button:enabled:active {
  background: #6c7c97;
  color: #ffffff;
  border-color: #6c7c97;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 24px;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 24px;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #7c89a1;
  color: #7c89a1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #ffffff;
  border-color: #7c89a1;
  color: #7c89a1;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #7c89a1;
  border-color: #7c89a1;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #6c7c97;
  border-color: #7c89a1;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #444444;
  border: 1.5px solid #d2dae3;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 200;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1.5px solid #d2dae3;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 32px;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 8px;
  color: #444444;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  padding: 0.5em 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.4em 0.86em;
  margin: 0;
  border: 0 none;
  color: #444444;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ebf1;
  color: #444444;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #5f6c85;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ebf1;
  color: #444444;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #7c89a1;
  color: #ffffff;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #424b5c;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #c8c8c8;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #c8c8c8;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #c8c8c8;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  padding: 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-paginator {
  background: #ffffff;
  color: #848484;
  border: none;
  border-width: 0;
  padding: 0 0;
  border-radius: 0;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #848484;
  min-width: 32px;
  height: 32px;
  margin: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e0e0e0;
  border-color: transparent;
  color: #848484;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 32px;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #848484;
  min-width: 32px;
  height: 32px;
  margin: 0;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #848484;
  min-width: 32px;
  height: 32px;
  margin: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #7c89a1;
  border-color: #7c89a1;
  color: #ffffff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e0e0e0;
  border-color: transparent;
  color: #848484;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #444444;
  border: 1.5px solid #d2dae3;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 200;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1.5px solid #d2dae3;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 32px;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 8px;
  color: #444444;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  padding: 0.5em 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.4em 0.86em;
  margin: 0;
  border: 0 none;
  color: #444444;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ebf1;
  color: #444444;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #7c89a1;
}
.p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #5f6c85;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.4em 0.86em;
  color: #444444;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "\e930";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "\e92c";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "\e933";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "\e92f";
  }
}
.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  padding: 1rem;
  border-radius: 0;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.143em;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #848484;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #444444;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #7c89a1;
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ebf1;
  color: #444444;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ebf1;
  color: #444444;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 32px;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 8px;
  color: #444444;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #5f6c85;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 0;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  color: #444444;
  padding: 0.143em;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #7c89a1;
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #848484;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ebf1;
  color: #444444;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #8f8f8f;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 12px 8px;
  border: 1px solid #c3beb0;
  border-width: 0 1px 0 0;
  font-weight: 200;
  color: #444444;
  background: #e0e0e0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  border-width: 0 0 1px 0;
  font-weight: 300;
  color: #444444;
  background: #ffffff;
}
.p-treetable .p-sortable-column {
  outline-color: transparent;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #c2c2c2;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #7c89a1;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e0e0e0;
  color: #444444;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #c2c2c2;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #e0e0e0;
  color: #444444;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #444444;
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #444444;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: transparent;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-width: 0 0 1px 0;
  padding: 4px 0px 4px 8px;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #444444;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #7c89a1;
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #ffffff;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #eaeaea;
  color: #444444;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #444444;
}
.p-treetable .p-column-resizer-helper {
  background: #7c89a1;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 6px 4px;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 2px 0px;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 15px 10px;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 5px 0px;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #8f8f8f;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #444444;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #f8f9fa;
  color: #444444;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: unset;
  color: #444444;
  background: transparent;
  font-weight: 200;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: transparent;
  border-color: unset;
  color: #444444;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: transparent;
  border-color: #dee2e6;
  color: #444444;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: transparent;
  color: #444444;
}
.p-accordion .p-accordion-content {
  padding: 0;
  border: none;
  background: transparent;
  color: #444444;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-card {
  background: #ffffff;
  color: #444444;
  box-shadow: none;
  border-radius: 0;
}
.p-card .p-card-body {
  padding: 16px 16px 16px 12px;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #848484;
}
.p-card .p-card-content {
  padding: 0;
}
.p-card .p-card-footer {
  padding: 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  border-radius: 0;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1.5px solid #d2dae3;
  color: #444444;
  background: #f8f9fa;
  font-weight: 200;
  border-radius: 0;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ebf1;
  border-color: #dee2e6;
  color: #444444;
}
.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-panel .p-panel-header {
  border: 1.5px solid #d2dae3;
  padding: 1rem;
  background: #f8f9fa;
  color: #444444;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 200;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #444444;
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #444444;
  padding: 8px 16px;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #9ba2aa;
  color: #526482;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #7c89a1;
  color: #526482;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: #444444;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1.5px solid #d2dae3;
  padding: 1rem;
  border-radius: 0;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-dialog {
  border-radius: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #c8c8c8;
}
.p-dialog .p-dialog-header {
  border-bottom: none;
  background: #ffffff;
  color: #444444;
  padding: 0px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 0;
  font-size: 0px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #444444;
  padding: 0px;
}
.p-dialog .p-dialog-footer {
  border-top: none;
  background: #ffffff;
  color: #444444;
  padding: 0px;
  text-align: right;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 48px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  background: #ffffff;
  color: #444444;
  border: 1px solid #c8c8c8;
  border-radius: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #7c89a1;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #6c7c97;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(200, 200, 200, 0);
  border-bottom-color: #c8c8c8;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #c8c8c8;
}

.p-sidebar {
  background: #ffffff;
  color: #444444;
  padding: 1rem;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.p-sidebar .p-sidebar-close {
  width: 2rem;
  height: 2rem;
  color: #848484;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover {
  color: #444444;
  border-color: transparent;
  background: #e9ebf1;
}
.p-sidebar .p-sidebar-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #ffffff;
  color: #444444;
  padding: 9px 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #ffffff;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #ffffff;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #ffffff;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #ffffff;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1.5px solid #d2dae3;
  color: #444444;
  border-bottom: 0 none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #444444;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 4px 0px 4px 8px;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #6c7c97;
  color: #ffffff;
  border-color: #6c7c97;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #6c7c97;
  color: #ffffff;
  border-color: #6c7c97;
}

.p-breadcrumb {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #7c89a1;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #444444;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #444444;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #444444;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #444444;
}

.p-contextmenu {
  padding: 0;
  background: #ffffff;
  color: #444444;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 200px;
}
.p-contextmenu .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-contextmenu .p-submenu-list {
  padding: 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ebf1;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #444444;
  border: none;
  border-radius: 0;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #444444;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-megamenu .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #444444;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #444444;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0;
  width: 200px;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ebf1;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-megamenu.p-megamenu-vertical {
  width: 200px;
  padding: 0;
}

.p-menu {
  padding: 0;
  background: #ffffff;
  color: #444444;
  border: none;
  border-radius: 0;
  width: 200px;
}
.p-menu .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #444444;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #444444;
  border: none;
  border-radius: 0;
}
.p-menubar .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #444444;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-menubar .p-submenu-list {
  padding: 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 200px;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ebf1;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #444444;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #444444;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #444444;
    background: #e9ebf1;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 13px 16px;
    color: #444444;
    border-radius: 0;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #444444;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #444444;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #444444;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ebf1;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #444444;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #444444;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #444444;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem transparent;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 39px;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 65px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 91px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 117px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 143px;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: unset;
  color: #444444;
  background: transparent;
  font-weight: 200;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: transparent;
  border-color: unset;
  color: #444444;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: transparent;
  border-color: #dee2e6;
  color: #444444;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: transparent;
  color: #444444;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0;
  border: none;
  background: transparent;
  color: #444444;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-slidemenu {
  padding: 0;
  background: #ffffff;
  color: #444444;
  border: none;
  border-radius: 0;
  width: 200px;
}
.p-slidemenu .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ebf1;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #444444;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #444444;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 13px 16px;
  color: #444444;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
  background: #ffffff;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #444444;
  border: 1px solid #c8c8c8;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #848484;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #7c89a1;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #444444;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #444444;
  padding: 8px 16px;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #9ba2aa;
  color: #526482;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #7c89a1;
  color: #526482;
}

.p-tieredmenu {
  padding: 0;
  background: #ffffff;
  color: #444444;
  border: none;
  border-radius: 0;
  width: 200px;
}
.p-tieredmenu .p-menuitem-link {
  padding: 13px 16px;
  color: #444444;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #444444;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ebf1;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #444444;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #444444;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #444444;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-submenu-list {
  padding: 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ebf1;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #444444;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #444444;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 9px 8px;
  margin: 0;
  border-radius: 0;
}
.p-inline-message.p-inline-message-info {
  background: #e4e7ec;
  border: 2px solid #92a2bd;
  border-width: 2px;
  color: #444444;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #444444;
}
.p-inline-message.p-inline-message-success {
  background: #ebf7e7;
  border: 2px solid #3db014;
  border-width: 2px;
  color: #444444;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #444444;
}
.p-inline-message.p-inline-message-warn {
  background: #fff6e8;
  border: 2px solid #ffad1f;
  border-width: 2px;
  color: #444444;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #444444;
}
.p-inline-message.p-inline-message-error {
  background: #fae5e5;
  border: 2px solid #d20000;
  border-width: 2px;
  color: #444444;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #444444;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 0 0;
  border-radius: 0;
}
.p-message .p-message-wrapper {
  padding: 0.429em 0.429em;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-message.p-message-info {
  background: #e4e7ec;
  border: 2px solid #92a2bd;
  border-width: 2px;
  color: #444444;
}
.p-message.p-message-info .p-message-icon {
  color: #444444;
}
.p-message.p-message-info .p-message-close {
  color: #444444;
}
.p-message.p-message-success {
  background: #ebf7e7;
  border: 2px solid #3db014;
  border-width: 2px;
  color: #444444;
}
.p-message.p-message-success .p-message-icon {
  color: #444444;
}
.p-message.p-message-success .p-message-close {
  color: #444444;
}
.p-message.p-message-warn {
  background: #fff6e8;
  border: 2px solid #ffad1f;
  border-width: 2px;
  color: #444444;
}
.p-message.p-message-warn .p-message-icon {
  color: #444444;
}
.p-message.p-message-warn .p-message-close {
  color: #444444;
}
.p-message.p-message-error {
  background: #fae5e5;
  border: 2px solid #d20000;
  border-width: 2px;
  color: #444444;
}
.p-message.p-message-error .p-message-icon {
  color: #444444;
}
.p-message.p-message-error .p-message-close {
  color: #444444;
}
.p-message .p-message-text {
  font-size: 1em;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.25em;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 0;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 8px 8px 0px 8px;
  border-width: 0 0 0 4px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 16px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 200;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #e4e7ec;
  border: 2px solid #92a2bd;
  border-width: 2px;
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #ebf7e7;
  border: 2px solid #3db014;
  border-width: 2px;
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #fff6e8;
  border: 2px solid #ffad1f;
  border-width: 2px;
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #fae5e5;
  border: 2px solid #d20000;
  border-width: 2px;
  color: #444444;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #444444;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #ebedef;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ebedef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0.2);
  color: #aeb6bf;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
  margin: 0.5rem 0;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #ebedef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #ebedef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #7c89a1;
  color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #7c89a1;
  color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #aeb6bf;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #aeb6bf;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-inplace .p-inplace-display {
  padding: 9px 8px;
  border-radius: 0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ebf1;
  color: #444444;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 0;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #7c89a1;
}
.p-progressbar .p-progressbar-label {
  color: #444444;
  line-height: 1.5rem;
}

.p-terminal {
  background: #ffffff;
  color: #444444;
  border: 1px solid #dee2e6;
  padding: 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-badge {
  background: #7c89a1;
  color: #444444;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #ffffff;
  color: #444444;
}
.p-badge.p-badge-success {
  background-color: #34a835;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #007ad9;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #ffc107;
  color: #444444;
}
.p-badge.p-badge-danger {
  background-color: #f44336;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-tag {
  background: #7c89a1;
  color: #444444;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 0;
}
.p-tag.p-tag-success {
  background-color: #34a835;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #007ad9;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #444444;
}
.p-tag.p-tag-danger {
  background-color: #f44336;
  color: #ffffff;
}

h1 {
  font: bold 36px/1.3 "BMWGroup_Condensed", Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: var(--ds-color-typography-base	);
}

h2 {
  font: bold 28px/1.3 "BMWGroup_Condensed", Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: var(--ds-color-typography-base	);
}

h3 {
  font: bold 18px/1.3 "BMWGroup_Condensed", Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: var(--ds-color-typography-base	);
}

h4 {
  font: bold 16px/1.3 "BMWGroup_Condensed", Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: var(--ds-color-typography-base	);
}

small,
span.small-text {
  font: 12px/1.2 Helvetica, Arial, sans-serif;
  color: var(--ds-color-typography-base	);
}

.label-text {
  font: bold 14px/1.4 Helvetica, Arial, sans-serif;
  color: var(--ds-color-typography-base	);
}

.invalid-input-text {
  font: 12px/1.2 Helvetica, Arial, sans-serif;
  margin-top: 2px;
  color: #d20000;
}

.p-button {
  font-weight: 600;
  min-width: 115px;
  min-height: 32px;
  font: bold 14px/1.33 "BMWGroup_Condensed", Arial, sans-serif;
}

.p-button-rounded,
.p-button-icon-only,
.p-selectbutton .p-button {
  min-width: unset;
}

.p-selectbutton .p-button,
.p-button.p-button-rounded {
  font-size: 12px;
}

.p-button.p-button-icon-only {
  font-size: 24px;
  padding: 0;
}

.p-button.p-button-sm {
  min-height: unset;
}

p-radiobutton .p-hidden-accessible {
  position: relative;
}
p-radiobutton .p-radiobutton-label {
  margin-right: 8px;
}
p-radiobutton .p-radiobutton .p-radiobutton-box {
  background-color: transparent;
}
p-radiobutton .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #848484;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight.p-focus {
  border-color: #6c7c97;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-weight: 600;
}

input[type=text].p-inputtext {
  border-bottom: 1px solid #8f8f8f;
  width: 100%;
  height: 32px;
}

textarea.p-inputtextarea {
  min-height: 90px;
  max-height: 200px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-bottom: 1px solid #8f8f8f;
}
textarea.p-inputtextarea:hover {
  color: #2f2f2f;
}

p-inputnumber {
  width: 100%;
}
p-inputnumber span.p-inputnumber {
  background-color: #f6f6f6;
  border-bottom: 1px solid #8f8f8f;
}
p-inputnumber span.p-inputnumber:hover {
  border-color: #444444;
}
p-inputnumber > span.p-inputnumber {
  display: inline-flex;
  flex-direction: row;
}
p-inputnumber input.p-inputnumber-input {
  display: inline-flex;
  width: 100%;
  padding: 0px 0px 0px 5px;
}
p-inputnumber .p-inputnumber-buttons-stacked {
  height: calc(32px + 1px);
  width: 100%;
}
p-inputnumber button.p-inputnumber-button {
  background-color: transparent;
  border: none;
  min-height: 12px;
}
p-inputnumber button.p-inputnumber-button.p-button.p-button-icon-only {
  width: 20px;
}
p-inputnumber button.p-inputnumber-button.p-button:hover {
  background-color: transparent;
}
p-inputnumber button.p-inputnumber-button.p-button:active {
  background-color: transparent;
}
p-inputnumber button.p-inputnumber-button .p-button-icon {
  color: #848484;
  font-size: 16px;
}
p-inputnumber button.p-inputnumber-button .p-button-icon:hover {
  color: #2f2f2f;
}

.p-dropdown {
  border-bottom: 1px solid #8f8f8f;
  height: 32px;
  width: 100%;
}
.p-dropdown .p-dropdown-label {
  padding: 8px 5px;
}
.p-dropdown .p-dropdown-clear-icon {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 iwp;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  color: #848484;
  font-size: 20px;
  margin-top: 6px;
  top: unset;
  right: 1.5rem;
}
.p-dropdown .p-dropdown-clear-icon:before {
  content: "\e93f";
}
.p-dropdown .p-dropdown-clear-icon:hover {
  color: #2f2f2f;
}
.p-dropdown .p-dropdown-trigger-icon {
  color: #848484;
  font-size: 17px;
}
.p-dropdown .p-dropdown-trigger-icon:hover {
  color: #2f2f2f;
}
.p-dropdown .p-dropdown-trigger {
  width: 2em;
}
.p-dropdown .p-dropdown-item {
  font-size: 14px;
  white-space: normal;
  overflow-wrap: anywhere;
}
.p-dropdown div.p-dropdown-items-wrapper {
  overscroll-behavior: none;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 5px 7px 5px 10px;
}
.p-dropdown-panel .p-dropdown-header .pi-search {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 iwp;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  font-size: 16px;
  color: #848484;
}
.p-dropdown-panel .p-dropdown-header .pi-search:before {
  content: "\e94a";
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter.p-inputtext {
  background-color: #ffffff;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter-icon {
  color: #848484;
  font-size: 17px;
  right: 0px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #7c89a1;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:hover {
  background-color: #7c89a1;
}

p-listbox .p-listbox .p-listbox-header {
  background-color: #ffffff;
}
p-listbox .pi-search {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 iwp;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
p-listbox .pi-search:before {
  content: "\e94a";
}
p-listbox .p-listbox .p-listbox-list .p-listbox-item {
  font-size: 14px;
  height: 40px;
  padding-left: 28px;
}
p-listbox .p-listbox .p-listbox-list .p-listbox-item i {
  float: right;
  font-size: 16px;
}
p-listbox .p-listbox .p-listbox-list .p-listbox-item div {
  display: inline;
  width: 100%;
}
p-listbox .p-listbox .p-listbox-list .p-listbox-item.p-highlight i {
  color: #ffffff;
}
p-listbox .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background-color: #7c89a1;
}
p-listbox .p-listbox .p-listbox-list .p-listbox-item.p-highlight:hover {
  background-color: #7c89a1;
}

.p-multiselect {
  border-bottom: 1px solid #8f8f8f;
  height: 32px;
  width: 100%;
  font-size: 14px;
  color: #444444;
}
.p-multiselect .p-multiselect-label {
  padding: 8px 5px;
}
.p-multiselect .p-multiselect-trigger-icon {
  color: #848484;
  font-size: 17px;
}
.p-multiselect .p-multiselect-trigger-icon:hover {
  color: #2f2f2f;
}
.p-multiselect .p-multiselect-trigger {
  width: 2em;
}
.p-multiselect .pi-times {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 iwp;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
}
.p-multiselect .pi-times:before {
  content: "\e93f";
}
.p-multiselect .p-multiselect-panel .p-multiselect-header {
  padding: 5px 3px 5px 5px;
  background-color: #ffffff;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-filter {
  background-color: #ffffff;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .p-checkbox {
  display: none;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .pi-search {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 iwp;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  font-size: 16px;
  color: #848484;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .pi-search:before {
  content: "\e94a";
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0px;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 20px;
  height: 20px;
}
.p-multiselect .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
  background-color: transparent;
}
.p-multiselect .p-multiselect-panel .p-multiselect-item {
  font-size: 14px;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: #d2dae3;
  color: #444444;
}
.p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:hover {
  background-color: #bdc6d6;
}

p-inputswitch {
  vertical-align: middle;
  padding: 0 4px;
}

p-calendar .p-calendar {
  background-color: #f6f6f6;
}
p-calendar .p-datepicker-trigger {
  background-color: transparent;
  border: none;
}
p-calendar .p-datepicker-trigger .pi-calendar {
  background: none;
  display: inline-block;
  font: normal normal normal 14px/1 iwp;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center;
  color: #848484;
  font-size: 20px;
}
p-calendar .p-datepicker-trigger .pi-calendar:before {
  content: "\e93c";
}
p-calendar .p-datepicker-trigger:hover {
  color: #2f2f2f;
  background-color: transparent;
}
p-calendar .p-button.p-button-icon-only {
  height: 32px;
  width: 32px;
  min-height: 32px;
}
p-calendar .p-button .p-button-icon.pi-calendar:hover {
  color: #444444;
}
p-calendar .p-button:enabled:hover {
  background-color: transparent;
}
p-calendar .p-calendar.p-calendar-w-btn {
  border-bottom: 1px solid #8f8f8f;
}
p-calendar .p-calendar.p-calendar-w-btn:hover {
  border-bottom: 1px solid #2f2f2f;
}
p-calendar input[type=text].p-inputtext {
  border-bottom: none;
}
p-calendar .p-calendar {
  display: flex;
}
p-calendar .p-calendar .p-datepicker {
  padding: unset;
}
p-calendar .p-calendar .p-datepicker .p-datepicker-header button .pi {
  font-size: 20px;
}
p-calendar .p-calendar .p-datepicker table.p-datepicker-calendar th {
  padding: 0.15rem;
}
p-calendar .p-calendar .p-datepicker table.p-datepicker-calendar .p-datepicker-weekheader {
  padding-right: 10px;
  padding-left: 10px;
}
p-calendar .p-calendar .p-datepicker table.p-datepicker-calendar .p-datepicker-weeknumber {
  font-weight: bold;
  color: #848484;
}
p-calendar .p-calendar .p-datepicker table.p-datepicker-calendar td span {
  padding: 0.8rem;
  width: 25px;
  height: 25px;
}
p-calendar .p-calendar .p-datepicker .p-timepicker {
  padding: 0px 0.5rem;
}
p-calendar .p-calendar .p-datepicker .p-timepicker .p-minute-picker button {
  width: 1.7rem;
  height: 1.7rem;
}
p-calendar .p-calendar .p-datepicker .p-timepicker .p-minute-picker span.pi {
  font-size: 20px;
}

.p-dialog {
  width: 535px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  display: none;
}

.dialog-title {
  font: bold 18px/1.3 "BMWGroup_Condensed", Arial, sans-serif;
  color: #444444;
  text-transform: uppercase;
  padding: 15px 24px 14px 24px;
}

.dialog-blue > * .dialog-title {
  border-bottom: 3px solid #7c89a1;
}

.dialog-red > * .dialog-title {
  border-bottom: 3px solid #f05960;
}

.dialog-content {
  font: 14px/1.43 Helvetica, Arial, sans-serif;
  color: #444444;
  min-height: 274px;
  padding: 36px 36px 24px 42px;
}

.dialog-footer {
  padding: 0px 36px 24px 36px;
  text-align: right;
}
.dialog-footer button {
  margin: 0 0 0 4px;
}

.modalMessageText1 {
  font: 14px/1.43 Helvetica, Arial, sans-serif;
  font-weight: bold;
  padding-bottom: 15px;
}

.modalMessageText2 {
  font: 14px/1.43 Helvetica, Arial, sans-serif;
  padding-bottom: 15px;
}

.p-toolbar {
  background-color: transparent;
  border: none;
  padding: 8px 0;
}

.p-sortable-column-icon {
  font-size: 12px;
}

tr > th > div {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}
tr > th > div > span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-treetable .p-sortable-column.p-highlight > * span {
  font-weight: 600;
}

.p-datatable-thead,
.p-treetable-thead {
  border-bottom: 1px solid #8f8f8f;
}

.p-datatable .p-datatable-tbody > tr.p-highlight,
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #e9ebf1;
  color: #444444;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #444444;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #444444;
}

.p-treetable-toggler i {
  font-size: 14px;
}

.p-datatable .p-datatable-tbody > tr > td > div,
.p-treetable .p-treetable-tbody > tr > td > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-datatable .p-datatable-tbody .pi-ellipsis-v,
.p-treetable .p-treetable-tbody .pi-ellipsis-v {
  color: #7c89a1;
  font-size: 18px;
  padding: 4px;
  margin-left: auto;
}

.p-datatable .p-datatable-tbody > tr,
.p-treetable .p-treetable-tbody > tr {
  min-height: 40px;
}

tr > td {
  vertical-align: top;
  line-height: 32px;
}

.p-treetable .p-treetable-tbody > tr > td.tableNoContent,
.p-datatable .p-datatable-tbody > tr > td.tableNoContent {
  text-align: center;
}

.p-tree {
  height: 100%;
  color: #444444;
  border: 0;
  padding: 0;
}

.p-tree-toggler-icon {
  font-size: 14px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label.p-state-highlight,
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: #ffffff;
  color: #444444;
  font-weight: 700;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  background-color: #ffffff;
  color: #444444;
}

.p-accordion .p-accordion-header .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link,
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  font: 16px/1 "BMWGroup_Condensed", Arial, sans-serif;
  border-top: 1.5px solid #d2dae3;
  padding: 12px 0;
  font-weight: normal;
  color: #666666;
  letter-spacing: 0.28px;
}

.p-accordion {
  border-bottom: 1.5px solid #d2dae3;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  font-size: 18px;
}

.accordionEditMode .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  padding-left: 32px;
}

.accordionHamburgerIcon,
.cdk-drag-preview .accordionHamburgerIcon {
  display: inline-block;
  font-size: 18px;
  padding-right: 8px;
  position: relative;
  top: 2px;
  left: -35px;
  color: #444444;
  font-weight: bold;
  cursor: grab;
}

.accordionActionButton {
  position: relative;
  float: right;
  bottom: 40px;
  font-size: 18px;
  color: #7c89a1;
  cursor: pointer;
  z-index: 1;
}

.p-card {
  background-color: white;
  height: 144px;
  width: 256px;
  overflow: hidden;
  border-left: solid 4px transparent;
  border-radius: 2px;
}
.p-card:not(.p-state-disabled):hover, .p-card:not(.p-state-disabled):active {
  cursor: pointer;
  border-color: #4a90e2;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.3);
}
.p-card .p-card-title {
  font: bold 14px/1.33 "BMWGroup_Condensed", Arial, sans-serif;
  color: #4a90e2;
}

.selected-card .p-card {
  border-color: #4a90e2;
  background-color: #f2f2f2;
}

.p-toast .p-toast-message {
  width: 350px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  width: 300px;
}

.p-toast-top-center {
  top: 96px;
}

.p-breadcrumb {
  padding: 0;
}

.p-breadcrumb ul li .p-menuitem-link {
  font-family: "BMWGroup_Condensed", Arial, sans-serif;
  font-weight: 600;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 6px;
  font-size: 12px;
}

.pcDetailRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--gutter-x);
  margin-bottom: var(--gutter-y);
  margin-top: var(--gutter-y);
}

.pcDetailMissionCard {
  max-width: 800px;
  min-width: 550px;
  flex: 1 1 550px;
}

.pcDetailAddCard {
  flex: 1 0 122px;
}

/* Customizations to the designer theme should be defined here */
body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
}
