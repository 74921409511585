@import './mixins.scss';

.edit {
  input.p-inputtext,
  span.p-calendar {
    background-color: $color-control-alternative;
  }
}

.card {
  padding: 15px;
  border: 2px solid $color-card-border;
  background-color: $color-card-bg;
  margin: 10px;
}

textarea.no-resize {
  resize: none;
}

.shadow {
  @include shadow(6px, 5px, 10px, $color-box-shadow);
}

.hover-shadow:hover {
  @include shadow(8px, 7px, 14px, $color-box-shadow);
}

%lineSeparatorBorder {
  border-bottom: 1px solid $color-control-separator;
  border-color: $color-control-separator-light;
}

%shadow-small {
  @include shadow(1px, 1px, 3px 2px, $color-box-shadow);
}

.elevated-shadow {
  @include shadows(
    0px 0px 2px $color-card-shadow-start,
    0px 16px 40px $color-card-shadow-end,
    0px 20px 48px $color-card-shadow-end,
    0px 24px 56px $color-card-shadow-end
  );
}

.roundButton {
  width: var(--ds-space-11x);
  height: var(--ds-space-11x);
  border-radius: 50%;

  &.ds-button.has-icon ds-icon {
    width: var(--ds-space-5x);
    height: var(--ds-space-5x);
  }
}

// ACTION MENU
.actionMenuHeader {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.actionMenuCell {
  width: 90px;
  min-width: 90px;
  max-width: 90px;

  .center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  i {
    font-size: xx-large;
  }
}

// LOADER
.loader-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  p {
    margin-top: 30px;
  }
}
