@import 'src/scss/variables/colors';

@mixin large-screen {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin shadow($top, $left, $blur, $color) {
  -webkit-box-shadow: $top $left $blur $color;
  -moz-box-shadow: $top $left $blur $color;
  box-shadow: $top $left $blur $color;
}

@mixin shadows($shadows...) {
  -webkit-box-shadow: $shadows;
  -moz-box-shadow: $shadows;
  box-shadow: $shadows;
}

@mixin coloredLeftBorderOnHover($color: $color-add-button) {
  border-left: 4px solid transparent;
  &:hover {
    border-left-color: $color;
  }
}

@mixin coloredTopBorderOnHover($color: $color-add-button) {
  border-top: 4px solid transparent;
  &:hover {
    border-top-color: $color;
  }
}

@mixin coloredTopBorderOnHoverError($color: $color-add-button) {
  border-top: 4px solid transparent;
  &:hover {
    border-top-color: $color-error-message;
  }
}

@mixin footerBar {
  background-color: $color-panel-bg;
  border-top: thin solid $color-panel-border-top;
  bottom: 0;
  box-shadow: 0px 0px 3px 1px $color-panel-box-shadow;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 8px 28px;
  position: fixed;
  transition: all 0.4s;
  width: 100%;

  &.active {
    bottom: 0;
  }
}
