ag-grid-angular {
  flex: 1;
  height: 100%;
  min-height: 100%;
}

.ag-theme-density.zebra {
  .row-highlight-warning {
    background-color: var(--ds-color-red-100) !important;
    &:hover {
      background-color: var(--ds-color-red-200) !important;
    }
  }
  .row-highlight-info {
    background-color: var(--ds-color-grey-100) !important;
    &:hover {
      background-color: var(--ds-color-grey-200) !important;
    }
  }
}
