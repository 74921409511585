$font-size-extra-small: 12px;
$font-size-small: 13px;
$font-size: 14px;
$font-size-medium: 15px;
$font-size-large: 16px;
$font-size-extra-large: 24px;
$font-size-large-heading: 42px;

$icon-font-size-medium: 22px;
$icon-font-size: 18px;
$icon-font-size-small: 16px;
