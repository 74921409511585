/*
 * BMW Group IT UI Guidelines
 * https://atc.bmwgroup.net/confluence/x/5-45BQ
 *
 * Last Update: 09-04-2018
 */

/* Basic Colors */
$color-bmw-basic1: #aaa38e;
$color-bmw-basic1-shade1: #c3beb0;
$color-bmw-basic1-shade2: #d4d1c6;
$color-bmw-basic1-shade3: #e5e3dd;

$color-bmw-basic2: #eeefea;

$color-bmw-basic3: #666666;
$color-bmw-basic3-shade1: #a3a3a3;
$color-bmw-basic3-shade2: #c2c2c2;
$color-bmw-basic3-shade3: #e0e0e0;

$color-bmw-basic4: #444444;

$color-bmw-basic5: #ffffff;

/* Highlight and Accent Colors */
$color-bmw-accent1: #fecb00;
$color-bmw-accent1-shade1: #fddf66;
$color-bmw-accent1-shade2: #ffea99;
$color-bmw-accent1-shade3: #fff5cc;

$color-bmw-accent2: #444444;
$color-bmw-accent2-shade1: #8f8f8f;
$color-bmw-accent2-shade2: #b4b4b4;
$color-bmw-accent2-shade3: #dadada;

$color-bmw-accent3: #a2ad00;
$color-bmw-accent3-shade1: #c7ce66;
$color-bmw-accent3-shade2: #dade99;
$color-bmw-accent3-shade3: #eceecc;

$color-bmw-highlight: #92a2bd;

$color-bmw-highlight-shade1: #bdc6d6;
$color-bmw-highlight-shade2: var(--ds-color-grey-100);
$color-bmw-highlight-shade3: #e9ebf1;

/* More Colors */
$color-bmw-signal1: #d20000;
$color-bmw-signal2: #3db014;
$color-bmw-signal3: #ffad1f;

$color-bmw-form-button: #efefef;
$color-bmw-form-button-main-dark: #6c7c97;
$color-bmw-form-button-main-light: #acbcd7;
$color-bmw-form-button-shade1: var(--ds-color-ocean-100);
$color-bmw-form-button-shade2: #526482;

$color-bmw-form-text: #000000;

$color-bmw-form-field: #d9d9d9;

$color-bmw-table-shade: #f6f6f6;

$color-bmw-link-shade: #7c89a1;

$color-bmw-form-border: #999999;
$color-bmw-form-border-accent: #ff9100;
