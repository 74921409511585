@import './bmw-colors.scss';

$color-notification-ping: #e82626;
$color-error-message: #f05960;
$color-success: $color-bmw-signal2;

$color-panel-primary: var(--ds-color-surface-base); // White
$color-panel-secondary: var(--ds-color-surface-info); // Blueish
$color-panel-body: var(--ds-color-surface-page); // Greyish
$color-panel-bg: $color-panel-primary;
$color-panel-header-bg: $color-bmw-basic3-shade2;
$color-panel-box-shadow: rgba(0, 0, 0, 0.3);

$color-card-shadow-start: rgba(0, 0, 0, 0.2);
$color-card-shadow-end: rgba(0, 0, 0, 0.1);
$color-control-shadow: rgba(0, 0, 0, 0.5);

$color-font-primary: $color-bmw-basic4;
$color-font-secondary: $color-bmw-basic5;
$color-font-label: $color-font-primary;
$color-font-content-header: $color-bmw-basic3-shade3;
$color-font-content-message: #999990;
$color-font-blue-hint: #0071c5;

$color-icon-primary: $color-bmw-basic4;
$color-icon-secondary: $color-bmw-basic5;
$color-icon-disabled: $color-bmw-basic3-shade2;

$color-icon-background-primary: #7c89a1;

$color-border: #d9d9d9;
$color-control-border: $color-bmw-accent2-shade1;
$color-control-border-alternative: $color-bmw-form-border;
$color-control-border-footer: #8f8f8f80;

$color-control-alternative: $color-bmw-basic5;
$color-control-separator: $color-bmw-highlight-shade2;
$color-control-separator-light: rgba($color-bmw-highlight-shade2, 0.5);
$color-control-hover-on: #2f2f2f;
$color-control-item-hover: $color-bmw-highlight-shade3;
$color-control-item-hover-light: $color-bmw-highlight-shade2;
$color-control-item-selected: $color-bmw-highlight-shade1;
$color-control-item-disabled: $color-bmw-table-shade;

$color-card-bg: $color-panel-secondary;
$color-card-border: var(--ds-color-border-action-strong);

$color-footer-map-bg: $color-bmw-basic4;
$color-footer-map-border: $color-bmw-basic4;

$color-dropdown-bg: $color-panel-primary;

$color-table-column-border: $color-bmw-basic5;
$color-table-head: $color-font-secondary;
$color-table-head-bg: #6b7b97;
$color-table-separator: $color-control-separator;
$color-table-message-bg: #f4f2ee;

$color-battery-high: var(--ds-color-green-300);
$color-battery-medium: var(--ds-color-yellow-200);
$color-battery-low: var(--ds-color-red-400);
$color-battery-border: var(--ds-color-cool-grey-700);

$color-status-label-default-bg: #9b9b9b;
$color-status-label-active-bg: #92a73b;
$color-status-label-draft-bg: #617899;
$color-status-label-archive-bg: #f7c436;

$color-add-button: #4a90e2;

$color-box-shadow: #00000029;
$color-label-bg: #7c89a1;

$color-user-settings-title-background: #ebebeb;
$color-user-settings-list-options: #0000001d;
$color-user-settings-options: #00000026;

$dolly-loaded: var(--ds-color-blue-500);
$dolly-free: var(--ds-color-cool-grey-300);

$color-error-message-bg: #ffefef;
$color-error-message-text: var(--ds-color-red-600);

$color-ng-loading-bar-shade1: #b5eb45;
$color-ng-loading-bar-shade2: #458aeb;

$color-loader-border-shade1: #0078d4;
$color-loader-border-shade2: #c7e0f4;

$color-bg-hover: #e5e8ef;

$color-slider-bg: #828282;
$color-slider-range-bg: #585858;

$color-icon-card-button: #88a3db;
$color-icon-card: #658dd5;

$color-line-separator: #c7cbd6;
$color-notification-bg: #f8f8f8;
$color-panel-border-top: #c3c3c3;
$color-line-segment-separator: #dcdee1;

$color-table-border-bottom: #edeff4;
$color-zone-table-border-bottom: #e7e7e7;
$color-searchbar-box-shadow: #8f8f8f21;
$color-footer-map-separator-border-right: #ffffff2e;

$color-separator-border: #bdc6d6;
$color-index-table-border-bottom: #f1f1f3;

$color-card-icon-background: #526482;
$color-card-icon-background2: #d3d3d3;

$color-card-icon-text: #526482;

$color-label-background1: #ecf3ff;
$color-label-background2: #3db01477;
$color-label-background3: #ffad1f77;
$color-label-background4: #037493;

$color-fatal-error-status: var(--ds-color-red-600);
$color-warning-error-status: var(--ds-color-persimmon-600);
$color-info-error-status: var(--ds-color-green-600);
$color-selected-card-border: #035970;

$color-selected-card-box-shadow1: rgba(0, 0, 0, 0.2);
$color-selected-card-box-shadow2: rgba(0, 0, 0, 0.12);
